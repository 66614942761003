exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".QCiTY {\n  background: none;\n  padding: 0;\n  width: 100%;\n  cursor: pointer;\n  height: 28px;\n  font-family: inherit; }\n\n._331hq {\n  padding-top: 8px; }\n\n._14JGn {\n  padding-left: 6px; }\n\n._113l5 {\n  padding-right: 2px; }\n\n.EqqOg {\n  display: -ms-flexbox;\n  display: flex; }\n\n._12LfK {\n  -ms-flex-align: center;\n      align-items: center; }\n\n._1iTQX {\n  border-width: 0;\n  border-style: solid;\n  border-bottom-width: 1px; }\n\n.Mubul {\n  border-color: var(--stroke-light-gray1); }\n\n.oy18L {\n  font-weight: 500; }\n\n._1Wm8q {\n  line-height: 24px; }\n\n._7dDjh {\n  font-size: 12px; }\n", ""]);

// Exports
exports.locals = {
	"toggle_button": "QCiTY",
	"pt_8px": "_331hq",
	"pl_6px": "_14JGn",
	"pr_2px": "_113l5",
	"flex": "EqqOg",
	"items_center": "_12LfK",
	"border_b": "_1iTQX",
	"border_light_gray1": "Mubul",
	"font_medium": "oy18L",
	"line_h_24px": "_1Wm8q",
	"text_12px": "_7dDjh"
};