import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './TableNew.scss';
import cx from 'classnames';
import Popover from 'material-ui/Popover/index';
import Formsy from 'formsy-react';
import FormsyCheckbox from '../Common/CheckBox';
import RaisedButton from '../Common/RaisedButton';
import { debounce } from 'formsy-material-ui/lib/utils';
import WithTooltip from '../Common/WithTooltip';


class RequestRourceFilter extends PureComponent {

  static defaultProps = {
    endingText:'',
    keys:['mine','broker','market'],
    titles:['My', 'Received', 'Market'],
    descriptions: [],
    filtersKey: 'channels',
    noDots: false,
    tooltipProps: {},
    filters: {},
    icons: [],
  }


  constructor(props) {
    super(props);
    const channels = props.filters[props.filtersKey] || this.getDefaultValues();
    this.state = {
      openPopover: false,
      title: this.getTitle(channels),
      channels,
    };
  }

  getDefaultValues() {
    return this.props.keys.reduce((accumulator, key) => {
      if (key) {
        accumulator[key] = true;
      }
      if (this.props.defaultFalse && this.props.defaultFalse.length && this.props.defaultFalse.includes(key)) {
        accumulator[key] = false;
      }
      return accumulator;
    }, {});
  }

  componentDidMount() {
    const channels = this.props.filters[this.props.filtersKey] || this.getDefaultValues();
    const title = this.getTitle(channels);
    const titleIcons = this.getTitleIcons(channels);
    this.setState({ channels, title, titleIcons });
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.filters !== nextProps.filters) {
      const channels = nextProps.filters[nextProps.filtersKey] || this.getDefaultValues();
      const title = this.getTitle(channels);
      const titleIcons = this.getTitleIcons(channels);
      if(!this.state.openPopover) {
        this.setState({ channels, title, titleIcons });
      }
    }
  }


  handleClick = (event) => {
    event.preventDefault();
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget,
      //channels: this.props.channels,
    });
  };

  handleRequestClose = () => {
    this.setState({
      openPopover: false,
    });
  }
  handleChange = debounce(() => {
    const channels = { ...this.state.channels }
    const title = this.getTitle(channels);
    if (this.state.title !== title) {
      const titleIcons = this.getTitleIcons(channels);
      this.setState({ title, titleIcons });
      this.props.handleFilter({ ...this.props.filters, [this.props.filtersKey]: channels });
    }
  }, 500);

  getTitle = (values) => {
    if (this.props.isCalculator) {
      if (this.isAllChecked(values)) {
        return this.props.allText;
      }
      if (this.isAllUnChecked(values) || (values.intermodalCalculator && values.freightCalculator && !values.myCalculations)) {
        return "All Types & All Calculations";
      }
    }
    if (this.props.isFillingSystem) {
      if (values.myCalculations) {
        return this.props.allText;
      }
      if (!values.myCalculations) {
        return "All Calculations";
      }
    }

    if (this.isAllChecked(values) || this.isAllUnChecked(values)) {
      return this.props.allText || 'All';
    }

    let ret = [];
    for (let i = 0; i < this.props.keys.length; i++) {
      const key = this.props.keys[i];
      if(values[key]) {
        ret.push(this.props.titles[i]?.text ?? this.props.titles[i])
      }
    }
    return ret.join(', ');

  }

  getTitleIcons = (values) => {
    if (!this.props.renderIcons) {
      return null;
    }
    if (this.isAllChecked(values) || this.isAllUnChecked(values)) {
      return this.props.allText || 'All';
    }
    let ret = [];
    for (let i = 0; i < this.props.keys.length; i++) {
      const key = this.props.keys[i];
      if (values[key] && key) {
        ret.push(<span className={s.icon}>{this.props.icons[i]}</span>);
      }
    }
    return <span className={s.icons_wrapper}>{ret}</span>;
  }

  isAllChecked(values) {
   return this.props.keys.reduce( (accumulator, key) => accumulator && (values[key] || !key), true);
  }
  isAllUnChecked(values) {
    return this.props.keys.reduce( (accumulator, key) => accumulator && !values[key], true);
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (this.labelEl.scrollHeight > 24 && !this.state.overflowed) {
      this.setState({ overflowed: true });
    } else if (!(this.labelEl.scrollHeight > 24) && this.state.overflowed) {
      this.setState({ overflowed: false });
    }
  }

  toggleAll = (e, value) => {
    const channels = this.props.keys.reduce((accumulator, key) => {
      if (key == null) {
        return accumulator;
      }
      accumulator[key] = value;
      return accumulator;
    }, {});
    this.setState({ channels }, this.handleChange);
  }

  toggleOne = (key, e, value) => {
    const channels = { ...this.state.channels };
    channels[key] = value;
    this.setState({ channels }, this.handleChange);
  }


  render() {
    return (
      <div style={{ ...this.props.style }} className={s.wrapper_check_popover}>
        <div>
          <RaisedButton
            primary
            style={{
              width: 'auto',
              height: '28px',
              borderRadius: '100px',
            }}
            buttonStyle={{
              backgroundColor: '#ffffff',
              borderRadius: '100px',
              padding:"0 9px",
              display:"block",
            }}
            labelStyle={{
              paddingLeft: '0',
              paddingRight: '0',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '11px',
              fontWeight: '400',
              lineHeight: '28px',
              textTransform: 'capitalize',
            }}
            onClick={this.handleClick}
            label={<span className={cx(s.label, this.state.overflowed && s.overflowed, this.props.rightIcon && s.with_icon)}><span
              style={{
              alignItems: 'flex-start',
              overflow: 'hidden',
              height: '24px',
              textAlign: 'left',
              wordBreak: 'break-all',
            }} ref={(el) => this.labelEl = el}>{this.state.titleIcons || this.state.title} {this.props.endingText}</span> {this.props.rightIcon} <span
              className={cx(s.arrow, this.state.openPopover ? s.active : '')}/></span>}
          />
          <Popover
            open={this.state.openPopover}
            anchorEl={this.state.anchorEl}
            useLayerForClickAway
            canAutoPosition
            animated={false}
            onRequestClose={this.handleRequestClose}
            style={{
              marginTop: '5px',
            }}
          >
            <div className={cx(s.filters_checkbox, this.props.noDots && s.no_dots, this.props.grouped && s.grouped)}>
              <Formsy.Form style={{ width: 'auto', minWidth: '175px', padding: '20px 15px 15px 15px', ...this.props.dropdownStyle }} ref={this.refForm}>
                {this.props.selectAllEnabled ?
                  <div className={cx()}>
                    <FormsyCheckbox
                      name={"selectAll"}
                      label={"Select all"}
                      value={this.isAllChecked(this.state.channels)}
                      iconStyle={{
                        marginRight: '4px',
                      }}
                      labelStyle={{
                        width: '100%',
                      }}
                      onChange={this.toggleAll}
                    />
                    <hr/>
                  </div>
                  : null}
                {this.props.grouped ?
                  this.props.groups.map(g => (
                    <div className={s.group}>
                      {this.props.keys.slice(g.start, g.end).map((k, i) => {
                        i += g.start;
                        if (!k) {
                          return <hr/>;
                        }
                        return (
                          <div className={s.group_item} key={k}>
                            <FormsyCheckbox
                              key={k}
                              name={k}
                              label={
                                <span className="flex align-items-center">
                                  {this.props.icons[i] ?
                                    <span className={cx('flex align-items-center', s.icon)}>
                                      {this.props.icons[i]}
                                    </span>
                                    : null}
                                  {`${this.props.titles[i]?.text ?? this.props.titles[i]} ${this.props.titles[i]?.endingText ?? this.props.endingText}`}
                                  <span className={s.right_icon}>
                                    {this.props.titles[i].rightIcon}
                                  </span>
                                </span>
                              }
                              value={!!this.state.channels[k]}
                              iconStyle={{
                                marginRight: '4px',
                              }}
                              labelStyle={{
                                width: '100%',
                              }}
                              {...(this.props.titles[i]?.props || {})}
                              onChange={this.toggleOne.bind(this, k)}
                            />
                            {this.props.descriptions[i] ?
                              <WithTooltip
                                showOnEventName="onClick"
                                {...this.props.tooltipProps}
                                tip={() => <div className="tooltip-default dark">
                                  {this.props.descriptions[i]}
                                </div>}
                              >
                                <span className={cx('material-icons-outlined', s.info_icon)}>info</span>
                              </WithTooltip>
                            : null}
                          </div>
                        );
                      })}
                    </div>
                  ))
                : this.props.keys.map((k, i) => {
                  if (!k) {
                    return <hr/>;
                  }
                  return (
                    <div className={cx('flex align-items-center')} key={k}>
                      <FormsyCheckbox
                        key={k}
                        name={k}
                        label={<span className="flex align-items-center">{this.props.icons[i] ? <span
                          className={cx('flex align-items-center', s.icon)}>{this.props.icons[i]}</span> : null} {`${this.props.titles[i]?.text ?? this.props.titles[i]} ${this.props.titles[i]?.endingText ?? this.props.endingText}`}<span className={s.right_icon}>{this.props.titles[i].rightIcon}</span></span>}
                        value={!!this.state.channels[k]}
                        iconStyle={{
                          marginRight: '4px',
                        }}
                        labelStyle={{
                          width: '100%',
                        }}
                        {...(this.props.titles[i]?.props || {})}
                        onChange={this.toggleOne.bind(this, k)}
                      />
                      {this.props.descriptions[i] ? <WithTooltip showOnEventName="onClick" {...this.props.tooltipProps}
                                                                 tip={() => <div
                                                                   className="tooltip-default dark">{this.props.descriptions[i]}</div>}><span
                        className={cx('material-icons-outlined', s.info_icon)}>info</span></WithTooltip> : null}
                    </div>
                  );
                })}

              </Formsy.Form>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

export default (withStyles(s)(RequestRourceFilter));
