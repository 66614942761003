import { vesselFormatters } from "../../Vessel/vesselFormatters";
import { vesselChecks } from "../../Vessel/vesselChecks";
import { date, dateRange, number } from "../../../core/format";

function numberWithUnit(val, unit) {
  if (val === '') return null

  const result = number(val)
  return result !== '---' ? result + unit : null
}

const fields = [
  { name: 'name', label: 'Ship name' },
  { name: 'imoNumber', label: 'IMO' },
  { name: 'typeByPurpose', label: 'Type by purpose' },
  { name: 'typeBySpecialty', label: 'Type by specialty' },
  { name: 'blt', label: 'Year of built' },
  { name: 'flagCountryName', label: 'Flag' },
  { name: 'builderV2', label: 'Builder yard' },
  {
    name: 'tcRate',
    label: 'T/C Index',
    select: [],
    value: ({ selectedVessel }) => vesselFormatters.tcRate.text(selectedVessel),
  },
  { name: 'registeredOwnerCompanyName', label: 'Ship Owner' },
  { name: 'managerCompanyName', label: 'Ship Manager' },
  { name: 'disponentOwnerCompanyName', label: 'Disponent Owner' },
  {
    name: 'dimensions.loa',
    label: 'LOA',
    exportLabel: 'LOA (m)',
    value: ({ serverVessel }) => serverVessel.dimensions && serverVessel.dimensions.loa,
  },
  {
    name: 'dimensions.lbp',
    label: 'LBP',
    exportLabel: 'LBP (m)',
    value: ({ serverVessel }) => serverVessel.dimensions && serverVessel.dimensions.lbp,
  },
  {
    name: 'dimensions.beam',
    label: 'B(m)',
    exportLabel: 'Beam (m)',
    value: ({ serverVessel }) => serverVessel.dimensions && serverVessel.dimensions.beam,
  },
  {
    name: 'dimensions.depth',
    label: 'Depth',
    exportLabel: 'Depth (m)',
    value: ({ serverVessel }) => serverVessel.dimensions && serverVessel.dimensions.depth,
  },
  {
    name: 'draft',
    label: 'Draft',
    exportLabel: 'Draft (m)',
  },
  {
    name: 'tonnage.gt',
    label: 'GT',
    value: ({ serverVessel }) => serverVessel.tonnage && serverVessel.tonnage.gt,
  },
  {
    name: 'tonnage.nt',
    label: 'NT',
    value: ({ serverVessel }) => serverVessel.tonnage && serverVessel.tonnage.nt,
  },
  {
    name: 'dwt',
    label: 'Deadweight',
    value: ({ serverVessel }) => numberWithUnit(serverVessel.dwt, 'mt'),
  },
  {
    name: 'capacity',
    label: 'Capacity',
    select: ['typeByPurpose', 'capacityV2.grain', 'capacityTanker.ttlCapacity'],
    value: ({ serverVessel }) =>
      vesselChecks.isTanker(serverVessel)
        ? serverVessel.capacityTanker && numberWithUnit(serverVessel.capacityTanker.ttlCapacity, 'm³')
        : serverVessel.capacityV2 && numberWithUnit(serverVessel.capacityV2.grain, 'm³')
  },
  {
    name: 'gearV2',
    label: 'Gears',
    select: ['geared', 'gearV2'],
    value: ({ serverVessel }) =>
      vesselFormatters.gearV2.shortText(serverVessel)
  },
  {
    name: 'grabV2',
    label: 'Grabs',
    select: ['grabbed', 'grabV2'],
    value: ({ serverVessel }) =>
      vesselFormatters.grabV2.shortText(serverVessel)
  },
  {
    name: 'pumps',
    label: 'Pumps',
    value: ({ serverVessel }) =>
      vesselFormatters.pumps.shortText(serverVessel)
  },
  {
    name: 'open',
    label: 'Open Position',
    select: ['open.name', 'open.from', 'open.until'],
    exportLabels: ['Open', 'Date'],
    values: ({ serverVessel }) => ({
      'Open': serverVessel.open?.name,
      'Date': dateRange(serverVessel.open?.from, serverVessel.open?.until),
    }),
  },
  {
    name: 'currentPosition',
    label: 'Coming from',
    exportLabels: ['Coming from', 'Coming from Date'],
    values: ({ serverVessel }) => ({
      'Coming from': serverVessel.currentPosition?.name,
      'Coming from Date': serverVessel.currentPosition?.date && date(serverVessel.currentPosition?.date),
    })
  },
  {
    name: 'destination',
    label: 'Going to',
    exportLabels: ['Going to', 'Going to Date'],
    values: ({ serverVessel }) => ({
      'Going to': serverVessel.destination?.name,
      'Going to Date': serverVessel.destination?.date && date(serverVessel.destination?.date),
    })
  },
  { name: 'refNo', label: 'Ref' },
  {
    name: 'techEquipCertificate.scrubberFitted',
    label: 'Scrubber fitted',
    value: ({ serverVessel }) =>
      vesselFormatters.fittedText(serverVessel.techEquipCertificate?.scrubberFitted),
  },
  {
    name: 'openHatch',
    label: 'Open hatch',
    value: ({ serverVessel }) =>
      vesselFormatters.fittedText(serverVessel.openHatch),
  },
  {
    name: 'boxShaped',
    label: 'Box shaped',
    value: ({ serverVessel }) =>
      vesselFormatters.fittedText(serverVessel.boxShaped),
  },
  {
    name: 'techEquipCertificate.imoFitted',
    label: 'IMO fitted',
    value: ({ serverVessel }) =>
      vesselFormatters.fittedText(serverVessel.techEquipCertificate?.imoFitted),
  },
  {
    name: 'techEquipCertificate.iceClass',
    label: 'Ice Class',
    value: ({ serverVessel }) =>
      serverVessel.techEquipCertificate?.iceClass,
  },
]

export const fieldsMap = Object.fromEntries(fields.map(x => [x.name, x]))

export const makeExportGroups = ({ hideVesselOpenPositions, hideTcRate }) => [
  {
    label: 'General Info',
    fields: [
      fieldsMap.name,
      fieldsMap.imoNumber,
      fieldsMap.typeByPurpose,
      fieldsMap.typeBySpecialty,
      fieldsMap.blt,
      fieldsMap.flagCountryName,
      fieldsMap.builderV2,
      // tcRate depends on the open position
      !hideVesselOpenPositions && !hideTcRate && fieldsMap.tcRate,
    ].filter(Boolean),
  },
  {
    label: 'Ownership',
    fields: [
      fieldsMap.registeredOwnerCompanyName,
      fieldsMap.managerCompanyName,
      fieldsMap.disponentOwnerCompanyName,
    ],
  },
  {
    label: 'Dimensions',
    fields: [
      fieldsMap['dimensions.loa'],
      fieldsMap['dimensions.lbp'],
      fieldsMap['dimensions.beam'],
      fieldsMap['dimensions.depth'],
      fieldsMap.draft,
    ],
  },
  {
    label: 'Tonnage',
    fields: [
      fieldsMap['tonnage.gt'],
      fieldsMap['tonnage.nt'],
      fieldsMap.dwt,
      fieldsMap.capacity,
    ],
  },
  {
    label: 'Gears',
    fields: [
      fieldsMap.gearV2,
      fieldsMap.grabV2,
      fieldsMap.pumps,
    ],
  },
  {
    label: 'Position',
    fields: hideVesselOpenPositions
      ? [
        fieldsMap.currentPosition,
        fieldsMap.destination,
      ]
      : [
        fieldsMap.open,
        fieldsMap.currentPosition,
        fieldsMap.destination,
        fieldsMap.refNo,
      ],
  },
  {
    label: 'Additional',
    fields: [
      fieldsMap['techEquipCertificate.scrubberFitted'],
      fieldsMap.openHatch,
      fieldsMap.boxShaped,
      fieldsMap['techEquipCertificate.imoFitted'],
      fieldsMap['techEquipCertificate.iceClass'],
    ],
  },
]

export const defaultValues = {
  name: true,
  imoNumber: true,
  typeByPurpose: true,
  typeBySpecialty: true,
  blt: true,
  flagCountryName: true,
  builderV2: true,
  tcRate: true,
  registeredOwnerCompanyName: true,
  managerCompanyName: true,
  disponentOwnerCompanyName: true,
  dwt: true,
  capacity: true,
  gearV2: true,
  grabV2: true,
  pumps: true,
}
