import React from 'react'
import ColoredText from '../../Monitor/RowBlocks/ColoredText'
import SecondText from '../../Monitor/RowBlocks/SecondText'
import VesselSelector from '../../Monitor/VesselSelector'
import t from './../../Monitor/TableNew.scss';
import s from './AllFleet.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CustomTooltip from '../../Common/CustomTooltip';
import InfoIcon from "material-ui/svg-icons/action/info";
import { ProgressLine } from '../../Common/ProgressVesselPosition';

const loadingStatus = {
  laden: {
    status: "laden",
    route: {
      progress: 1,
    },
  },
  ballast: {
    status: "ballast",
    route: {
      progress: 1,
    },
  },
  none: {
    status: "undefined",
    route: {
      progress: 0,
    },
  },
};

function TableHead(props) {
  const { refList, isUserAnonymous, isDark, rows, selectAll } = props;

  return (
    <div className={cx(t.wrapper_header, isDark ? t.dark : t.light)}>
      <div className={cx(t.table_row, t.header, s.all_fleet_table_row)}>
        <div className={cx(t.text)} style={{ display: 'flex', alignItems: 'flex-start', overflow: 'unset' }}>
          {
            isUserAnonymous
              ? null
              : <VesselSelector refList={refList} isDark={isDark} vertical handleCheck={selectAll} vessels={rows} />
          }
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            DISP OWNER<br />
          <SecondText>TAGS</SecondText>
          </div>
        </div>
        <div className={cx(t.text)}>
          Vessel<br />
          <SecondText>IMO / Type</SecondText>
        </div>
        <div className={cx(t.text)}>
          <ColoredText>DWT</ColoredText>
          <SecondText>Capacity</SecondText>
        </div>
        <div className={cx(t.text)}>
          Gear<br />
          <SecondText>Rating</SecondText>
        </div>
        <div className={cx(t.text, s.with_tooltip)}>
          <CustomTooltip
          dark={!isDark}
            tooltip={
              <div className={s.loading_tooltip}>
                <div className={s.loading_tooltip_item}>
                  <span className={s.tooltip_arrow}><ProgressLine showPercent={false} onRoute={loadingStatus.laden} loadingStatus={loadingStatus.laden.status} /></span>
                  <span className={s.tooltip_text}>Laden</span>
                </div>
                <div className={s.loading_tooltip_item}>
                  <span className={s.tooltip_arrow}><ProgressLine showPercent={false} onRoute={loadingStatus.ballast} loadingStatus={loadingStatus.ballast.status} /></span>
                  <span className={s.tooltip_text}>Ballast</span>
                </div>
                <div className={s.loading_tooltip_item}>
                  <span className={s.tooltip_arrow}><ProgressLine showPercent={false} onRoute={loadingStatus.none} loadingStatus={loadingStatus.none.status} /></span>
                  <span className={s.tooltip_text}>Unknown</span>
                </div>
              </div>}
          >
            Coming From 
          <InfoIcon
            style={{
              width: "15px",
              height: "15px",
              color: "var(--profile-grey-label)",
              marginLeft: "6px",
            }}
          />
          </CustomTooltip>
          <SecondText>Speed</SecondText>
        </div>
        <div className={cx(t.text)}>
          Going To<br />
          <SecondText>Date</SecondText>
        </div>
        <div className={cx(t.text)}>Status</div>
      </div>
    </div>
  )
}

export default withStyles(t, s)(TableHead)
