import React from 'react';
import Layout from '../../components/Layout';
import {
  getVesselsList,
} from "../../actions/allFleet";
import {getAccountFilters} from "../../actions/login";

function routeHandler(path = "/", action, props = {}, ElName = 'MyFleet') {
  if (!action) {
    action = async function ({ params, hash, store, token, next, ...rest }) {
      let El = null;
      switch (ElName) {
        case 'MyFleet':
           El = ((await import(/* webpackChunkName: 'Fleet' */ '../../components/Vessel/MyFleet/MyFleet')).default);
      }
      try {
        if (!rest.previousPath || rest.pathname.slice(16) !== rest.previousPath.slice(16)) {
          await store.dispatch(getAccountFilters(token));
        }
      } catch (e) {
        console.error(e);
      }
      return {
        title: "SHIPNEXT - Shipping Marketplace and Transportation Platform",
        component: (
          <Layout>
            <El hash={hash} {...params} {...props} />
          </Layout>
        )
      };
    };
  }
  return {
    path,
    action
  };
}

function spFleetRoute(path = "/", action, props = {}) {
  if (!action) {
    action = async function ({
      params,
      hash,
      store,
      token,
      next,
      ...rest
    }) {
      const Spfleet = ((await import(/* webpackChunkName: 'Fleet' */ '../../components/Vessel/Spfleet/Spfleet')).default);
      if (!rest.previousPath || rest.pathname.slice(16) !== rest.previousPath.slice(16)) {
        await store.dispatch(getAccountFilters(token));
      }
      return {
        title: "SHIPNEXT - Shipping Marketplace and Transportation Platform",
        component: (
          <Layout>
            <Spfleet hash={hash} {...params} {...props} />
          </Layout>
        )
      };
    };
  }
  return {
    path,
    action
  };
}

export const populateRoutes = ComponentName => ([
  routeHandler("/", null, {}, ComponentName),
  routeHandler("/create", null, { create: true }, ComponentName),
  routeHandler("/tc", null, { tc: true }, ComponentName),
  routeHandler("/tc/:bidId", null, { tc: true, bidOpen: true }, ComponentName),
  routeHandler("/:vesselId", null, {}, ComponentName),
  routeHandler("/:vesselId/edit", null, { edit: true }, ComponentName),
  routeHandler("/:vesselId/:tab/:bidId?/:tcOffer?", null, {}, ComponentName)
])

export const allFleetAction = (props = {}) => async ({ params, hash, token, store, ...rest }) => {
  const { user } = store.getState().login;
  const AllFleet = ((await import(/* webpackChunkName: 'Fleet' */ '../../components/Vessel/AllFleet')).default);
  if (user) {
    try {
      if (!rest.previousPath || rest.pathname.slice(16) !== rest.previousPath.slice(16)) {
        await store.dispatch(getAccountFilters(token));
      }
    } catch (e) {
      console.error(e);
    }
  }

  return ({
    title: "Shipnext Fleet Monitor: Real-Time Fleet Tracking & Management",
    description: "Track and manage your fleet in real-time with Shipnext's Fleet Monitor. Enhance efficiency, safety, and performance with advanced monitoring tools.",
    component: (
      <Layout>
        <AllFleet hash={hash} {...params} {...props} />
      </Layout>
    ),
    public: !props.tc,
  })
}

export default {
  path: "/fleetmonitor",
  children: [
    {
      path: "/allfleet",
      children:
        [
          routeHandler("/", allFleetAction()),
          routeHandler("/create", allFleetAction({ create: true })),
          routeHandler("/tc", allFleetAction({ tc: true })),
          routeHandler("/tc/:bidId", allFleetAction({ tc: true, bidOpen: true })),
        ],
    },
    {
      path: "/myfleet",
      children:
        populateRoutes('MyFleet')
    },
    {
      path: "/spfleet",
      children: [spFleetRoute(), spFleetRoute("/:vesselId", null, {}), spFleetRoute("/:vesselId/edit", null, { edit: true })]
    }
  ]
};
