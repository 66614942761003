import React, { Component } from "react";
import FormsyCheckbox from '../../Common/CheckBox';
import cx from 'classnames';
import s from "./Export.scss";

/**
 * @property {Array<object>} props.exportGroups - Config containing exporting fields info.
 * @property {object} props.group - A group of fields for export.
 * @property {object} props.values - Current set of selected fields for export.
 * @property {Function} props.setValues - To update export selections.
 */
export default class ExportGroup extends Component {
  handleGroupChange = (e, value) => {
    const { name } = e.target
    const group = this.props.exportGroups.find(group => group.label === name)

    const set = {}
    for (const field of group.fields) {
      set[field.name] = value
    }

    this.props.setValues(set)
  }

  handleValueChange = (e, value) => {
    this.props.setValues({ [e.target.name]: value })
  }

  render() {
    const { group, values } = this.props

    const someChecked = group.fields.some((field) => values[field.name])
    const everyChecked = group.fields.every((field) => values[field.name])

    return (
      <div className={s.group}>
        <div className={s.group_title}>
          <FormsyCheckbox
            className={cx(s.group_checkbox, someChecked && !everyChecked ? s.semi_checked : undefined)}
            label={group.label}
            name={group.label}
            labelStyle={{
              fontSize: 14,
              fontWeight: 'bold',
              color: '#333',
            }}
            inputStyle={{
              width: 16,
            }}
            value={everyChecked}
            onChange={this.handleGroupChange}
          />
        </div>

        <div className={s.group_content}>
          {group.fields.map((field, i) => (
            <div key={i}>
              <FormsyCheckbox
                label={field.label}
                name={field.name}
                labelStyle={{
                  fontSize: 14,
                  color: '#333',
                  whiteSpace: 'nowrap',
                }}
                value={values[field.name]}
                onChange={this.handleValueChange}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}
