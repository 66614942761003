import React, { Component } from 'react';
import BlurIfNoSubscription from "../../Signup/BlurIfNoSubscription";
import Hoverable from "../../Common/Hoverable";
import ExcelIcon from "../../Icons/ExcelIcon";
import ExportDialog from "./ExportDialog";

/**
 * @property {Object[]} props.selectedVessels - Array of selected vessel objects.
 * @property {string} props.storageKey - A key for storing data in local storage.
 * @property {boolean} props.hideVesselOpenPositions? - Hide fields related to the open position: open position, ref, and tc rate.
 * @property {boolean} props.hideTcRate? - Hide TC Rate field if the table on the page does not have it, like in suitable fleet.
 */
export default class ExportButton extends Component {
  state = {
    show: false
  }

  toggle = (value) => {
    this.setState((s) => ({ ...s, show: value === undefined ? !s.show : value }))
  }

  close = () => this.toggle(false)

  render() {
    const { selectedVessels, storageKey, hideVesselOpenPositions, hideTcRate } = this.props
    const { show } = this.state

    return (
      <div>
        <BlurIfNoSubscription classes dontBlur>
          <Hoverable onClick={this.toggle}>
            <ExcelIcon
              color='#285596'
              style={{
                fill: `var(--filter-sort-clr)`,
                width: 20,
                height: 20
              }}
            />
          </Hoverable>
        </BlurIfNoSubscription>

        {show && (
          <ExportDialog
            selectedVessels={selectedVessels}
            onClose={this.close}
            storageKey={storageKey}
            hideVesselOpenPositions={hideVesselOpenPositions}
            hideTcRate={hideTcRate}
          />
        )}
      </div>
    )
  }
}
