export default function ALLFLEET_UPDATE_VESSELS(state, action) {
    const newVessels = action.payload;
    let allVessels = [...state.data];

    const vesselMap = Object.fromEntries(newVessels.map(vessel => [vessel._id, vessel]));

    allVessels = allVessels.map(vessel => vesselMap[vessel._id] || vessel);



    return { ...state, data: allVessels };
  }
