import React, { PureComponent } from "react";
import styles from "./BookFreightButton.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { number } from "../../../core/format";
import cx from "classnames";
import Cargo from "../../../core/api/Cargo";
import history from "../../../core/history";
import Loader from "../../Common/Loader";

class BookFreightButton extends PureComponent {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }

  getCargoVesselData = async () => {
    try {
      console.log(this.props.user);
      const res = await Cargo.getCargoAndVessel(
        this.props.cargoId,
        this.props.vesselId,
        this.props.user
      );
      const channel = res.vessel.channels.find(ch => {
        console.log(ch);
        return ch.status.state === "waiting";
      });
      this.setState({ channel });
      return channel;
    } catch (error) {
      console.log(error);
    }
  };

  omponentWillUnmount() {
    this.unmounting = true;
  }

  refresh = async () => {
    if (this.unmounting) {
      return;
    }
    history.replace(window.location.pathname);
  };

  buttonHandler = async event => {
    event.stopPropagation();
    event.preventDefault();
    if (this.state.channel) {
      const bid = this.state.channel?.status?.bid;
      this.setState({ loading: true });
      try {
        await this.props.fixHandler({
          bidId: bid._id,
          cargoRequest: bid.cargoRequest,
          bid
        });
      } catch (error) {
        this.setState({ loading: false });
        console.log(error);
      }
      this.refresh();
    }
  };

  componentDidMount() {
    if (!this.props.withInnerHandler) {
      this.getCargoVesselData();
    }
  }

  render() {
    const {
      freightCurrency,
      freightTerm,
      freightValue,
      hasMultipleFreights,
      theme,
    } = this.props;

    return (
      <button
        className={cx(styles.button_wrapper)}
        onClick={
          this.props.withInnerHandler
            ? this.buttonHandler
            : this.props.fixHandler
        }
      >
        {this.state.loading ? (
          <div className={cx(styles.loader_wrapper)}>
            <Loader size={20} isDark />
          </div>
        ) : (
          <div className={cx(styles.content_wrapper)}>
            <div className={cx(styles.currency_wrapper, theme && styles.dark)}>
              <span className={cx(styles.value)}>
                {number(
                  freightValue,
                  freightCurrency || "USD",
                  (hasMultipleFreights && "+") || ""
                ).replace(" +", "+")}
              </span>
              <span className={cx(styles.term)}>{`${freightTerm}`}</span>
            </div>
            <div className={cx(styles.book)}>Book Freight</div>
          </div>
        )}
      </button>
    );
  }
}

export default withStyles(styles)(BookFreightButton);
