import React, { PureComponent } from "react";
import s from "./Monitor.scss";
import { connect } from 'react-redux';
import RaisedButton from "../Common/RaisedButton";
import Link from "../Link";
import cx from "classnames";
import Calculator from "../Icons/Calculator";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import VesselPreview from "../Vessel/Preview/VesselPreviewNew";
import Loader from "../Common/Loader";
import ExtendedForm from '../Common/ExtendedForm';

import ActionsMenu from "./../Vessel/Preview/ActionsMenu";
import NarrowVesselAutocomplete from '../NewInputs/NarrowVesselAutocomplete';
import history from '../../core/history';
import ConditionalBlur from "../Common/ConditionalBlur";

import ArrowPopover from "../Common/ArrowPopover";
import { renderMenu } from "../Contracts/links";

class LoggedInHeader extends PureComponent<{ spfleet: any, claimUpdate: any, updateCurrentVessel: any, onAddedChannel: any, exclusionsAnchor: null, baseUrl: any, ship: any, props: Readonly<{}> & Readonly<{ children?: React.ReactNode }>, dontShowRatingAndComments: any, editLink: null, showSearch: boolean }> {

  handleVesselSelected = (vessel) => {
    if(!vessel || !vessel._id) {
      return;
    }
    history.push(`${this.props.baseUrl}${vessel.imoNumber}-${vessel.name.replace(/ /g,'-').toLowerCase()}`);
    this.autocomplete.input.resetValue();
  }
  render() {
    const { selectedVessel } = this.props;
    // vessel object is in selectedVessel.vessel on the Trading Desk, but it is just selectedVessel in Exchange.
    const vessel = 'vessel' in selectedVessel ? selectedVessel.vessel : selectedVessel
    // `channels` is always in selectedVessel
    const { channels } = selectedVessel

    const isBookFreight = vessel.typeByPurpose === "CONT" && channels.some((channel) => {
      return Boolean(channel && channel.status.freightCurrency && channel.status.freightTerm && channel.status.freightValue);
    });

    return <div className={s.cargo_details_header}>
      <div className={"flex"}>
      {this.props.props?.selectEntity === 'myVessels' && (
        <ArrowPopover
          renderer={renderMenu}
          val={"Vessel information"}
          list={this.props.props?.navLinks}
        />
      )}
      
      <ActionsMenu spfleet={this.props.spfleet} claimUpdate={this.props.claimUpdate}
                   updateCurrentVessel={this.props.updateCurrentVessel} selectedCallback={this.props.claimUpdate}
                   onAddedChannel={this.props.onAddedChannel} exclusionsAnchor={this.props.exclusionsAnchor}
                   baseUrl={this.props.baseUrl} ship={this.props.ship} />

      {this.props.showSearch ?
        <ExtendedForm>
          <div className={cx(s.public_vessel_autocomplete_wrapper)}>
            <NarrowVesselAutocomplete
              classes={s.public_vessel_autocomplete}
              name="vesselAutocomplete"
              autoWidth
              popoverProps={{ style: { width: '347px' }, padding: '0' }}
              placeholder="Enter Ship name or IMO"
              onNewRequest={this.handleVesselSelected}
              ref={ (el) => this.autocomplete = el}
            />
            <span className={cx("material-icons", s.icon)}>
              search
            </span>
          </div>
        </ExtendedForm>
        : null}
      </div>
      {/*<VesselPositionDialog vessel={props.selectedVessel} user={props.user} withTitle />*/}
      <div className={s.cargo_details_buttons}>
        {this.props.ship?.canFreightRequest &&
        !this.props.ship?.isUserOwner &&
        !this.props.ship?.isVesselOwnerInSameCompany ? (
          <ConditionalBlur spanStyle={{display:'flex'}} isBlurred={!this.props.ship?.dontBlurFreightRequest} dontBlur>
            <RaisedButton
              primary
              styles="small"
              label="Request Freight"
              style={{
                marginRight: '8px',
                borderRadius: '15px',
              }}
              buttonStyle={{
                borderRadius: '15px',
              }}
              labelStyle={{
                paddingLeft: '12px',
                paddingRight: '12px',
                fontFamily: 'Roboto Condensed',
                fontSize: '11px',
                fontWeight: '700',
                webkitFontSmoothing: 'antialiased',
                textTransform: 'uppercase',
              }}
              containerElement={
                <Link
                  to={`/main-deck/general/${this.props.props.selectedCargo?._id ||
                  '-'}/${this.props.ship?._id}/request`}
                />
              }
            />
          </ConditionalBlur>
        ) : null}
        {!isBookFreight &&
        this.props.ship?.canOfferHire &&
        !this.props.ship?.isUserOwner &&
        !this.props.ship?.isVesselOwnerInSameCompany ? (
          <ConditionalBlur spanStyle={{display:'flex'}} isBlurred={!this.props.ship?.dontBlurOfferHire} dontBlur>
            <RaisedButton
              primary
              label="Offer hire"
              styles="small"
              className={s.button_preview_vessel}
              containerElement={
                <Link to={this.props.baseUrl === '/vessel/' ? `/vessel/${[this.props.ship.imoNumber, ...this.props.ship.name.toLowerCase().split(" ")].join("-")}/${this.props?.props?.tc ? 'tc' : '-'}/${this.props?.props?.bidId ? this.props.props.bidId : '-'}/tc-offer` : `/main-deck/${this.props.props.selectedOrder?._id ? 'tc' : 'general'}/${this.props.props.selectedCargo?._id || this.props.props.selectedOrder?._id ||
                  '-'}/${this.props.ship?._id}/tc-offer`}/>
              }
              style={{
                minWidth: 'auto',
                marginRight: '8px',
                borderRadius: '15px',
              }}
              buttonStyle={{
                backgroundColor: '#4380C7',
                borderRadius: '15px',
              }}
              labelStyle={{
                height: '100%',
                paddingLeft: '12px',
                paddingRight: '12px',
                fontFamily: 'Roboto Condensed',
                fontSize: '11px',
                fontWeight: '700',
                webkitFontSmoothing: 'antialiased',
                textTransform: 'uppercase',
                color: '#ffffff',
              }}
            />
          </ConditionalBlur>
        ) : null}
        {
          this.props.ship
            ? (
              <div className={cx('raised-button', s.button_preview_vessel)}>
                {
                  !this.props.dontShowRatingAndComments
                    ? (
                      <a
                        href={`/calculator/${this.props.ship?._id}/${vessel?.openChannel?._id || '-'}/${(this.props.selectedCargo && this.props.selectedCargo?._id) || '-'}`}
                        target="_blank"
                        style={{
                          display: 'inline-block',
                          height: '100%',
                        }}
                      >
                        <RaisedButton
                          primary
                          styles="small"
                          label={<span>
                                  <Calculator
                                    style={{
                                      marginRight: '6px',
                                    }}
                                  />
                                  Freight calculator
                                </span>}
                          style={{
                            minWidth: 'auto',
                            borderRadius: '15px',
                          }}
                          buttonStyle={{
                            background: 'transparent',
                            border: '1px solid #D2D2D2',
                            borderRadius: '15px',
                          }}
                          labelStyle={{
                            height: '100%',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            fontSize: '11px',
                            fontWeight: '700',
                            webkitFontSmoothing: 'antialiased',
                            textTransform: 'uppercase',
                            color: '#4380C7',
                          }}
                        />
                      </a>
                    )
                    : null
                }

                {
                  this.props.editLink
                }
              </div>
            )
            : null
        }
        <a className={s.close} onClick={this.props.props.handleVesselClosed}>
          <NavigationClose/>
        </a>
      </div>
    </div>;
  }
}

class AnonymousHeader extends PureComponent<{ handleVesselClosed: function }> {
  handleVesselSelected = (vessel) => {
      if(!vessel || !vessel._id) {
        return;
      }
      history.push(`/vessel/${vessel.imoNumber}/${vessel.name}`);
      this.autocomplete.input.resetValue();
  }

  render() {
    return (<div className={cx(s.cargo_details_header, s.public_vessel_header)}>
      <div className={s.cargo_details_buttons}>

          <RaisedButton primary label="SHOW on TRADING DESK" styles="xs" containerElement={<Link to={`/login`} />} />
        <ExtendedForm>
          <div className={cx(s.public_vessel_autocomplete_wrapper)}>
            <NarrowVesselAutocomplete
              classes={s.public_vessel_autocomplete}
              name="vesselAutocomplete"
              autoWidth
              popoverProps={{ style: { width: '347px' }, padding: '0' }}
              placeholder="Enter Ship name or IMO"
              onNewRequest={this.handleVesselSelected}
              ref={ (el) => this.autocomplete = el}
            />
            <span className={cx("material-icons", s.icon)}>
              search
            </span>
          </div>
        </ExtendedForm>
      </div>
      <a className={s.close} onClick={this.props.handleVesselClosed}>
        <NavigationClose />
      </a>
    </div>);
  }
}

export class VesselOpenedDetails extends PureComponent {
  static defaultProps = {
    basePath: "/main-deck/general/",
    myfleet: false,
    dontShowRatingAndComments: false,
    onAddedChannel: () => undefined,
    updateCurrentVessel: () => undefined,
    changesCallback: () => undefined,
    showSearch: false,
    vesselPreviewProps: {},
  }

  state = {
    previewInstance: null
  };

  render() {
    const props = this.props;
    const ship = props.selectedVessel?.vessel
      ? props.selectedVessel.vessel
      : props.selectedVessel || null;

    /*     if (!ship) return (
      <div style={{height: '100%', width: '100%'}}>
        <Loader />
      </div>
    ); */
    let editLink = null;

    let basePath = this.props.basePath;

    if (!this.props.myfleet) {
      // TODO: come up with better solution
      if (props.type !== 'tc') {
        basePath += `${(props.selectedCargo && props.selectedCargo._id) || '-'}/`
      }
      else {
        basePath += `${(props.selectedOrder && props.selectedOrder._id) || '-'}/`
      }
    }
    if (ship?.isVesselOwnerInSameCompany && this.props.actionType !== "create" && ship?.typeByPurpose !== "TANK") {
      editLink = (
        <Link
          to={`${basePath}${ship._id}/edit`}
          style={{
            display: "inline-block",
            height: "100%"
          }}
        >
          <RaisedButton
            primary
            styles="small"
            label={<span>Edit</span>}
            style={{
              minWidth: "auto",
              borderRadius: "15px",
              marginLeft: "7px"
            }}
            buttonStyle={{
              background: "transparent",
              border: "1px solid #D2D2D2",
              borderRadius: "15px"
            }}
            labelStyle={{
              height: "100%",
              paddingLeft: "12px",
              paddingRight: "12px",
              fontSize: "11px",
              fontWeight: "700",
              webkitFontSmoothing: "antialiased",
              textTransform: "uppercase",
              color: "#4380C7"
            }}
          />
        </Link>
      );
    }

    return (
      <div className={s.cargo_details}>
        {this.props.user ?
          <LoggedInHeader spfleet={this.props.spfleet} claimUpdate={this.props.changesCallback}
                          updateCurrentVessel={this.props.updateCurrentVessel} onAddedChannel={this.props.onAddedChannel}
                          exclusionsAnchor={this.state.previewInstance} baseUrl={this.props.basePath} ship={ship}
                          props={props} dontShowRatingAndComments={this.props.dontShowRatingAndComments}
                          editLink={editLink}
                          handleVesselClosed={this.props.handleVesselClosed}
                          selectedCargo={this.props.selectedCargo}
                          selectedVessel={this.props.selectedVessel}
                          showSearch={this.props.showSearch}
          />
          : <AnonymousHeader ship={ship} baseUrl={this.props.basePath} handleVesselClosed={this.props.handleVesselClosed} />}

        <div className={s.vessel_details_wrapper}>
          <div>
            <VesselPreview selectEntity={this.props.selectEntity} showDescription={this.props.showDescription} changesCallback={this.props.changesCallback} attachAsRef={instance => this.setState({
              previewInstance: instance
            })} myfleet={this.props.myfleet} updateCurrentVessel={this.props.updateCurrentVessel}
                           onAddedChannel={this.props.onAddedChannel}
                           dontShowRatingAndComments={this.props.dontShowRatingAndComments} removeTriangle={false}
                           vessel={ship} basePath={this.props.basePath} channels={props.selectedVessel?.channels}
                           cargo={(props.selectedCargo)} monitor orderId={this.props.selectedOrderId} {...this.props.vesselPreviewProps}/>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ user: state.login?.user }))(VesselOpenedDetails);
