exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MsP9J {\n  padding: 0 !important;\n  background: transparent !important;\n}\n\n.MsP9J.show {\n  opacity: 1 !important;\n}\n\n.MsP9J::before {\n  background: transparent !important;\n}\n\n.MsP9J::after {\n  background: transparent !important;\n  border-color: white !important;\n  z-index: 0 !important;\n  border: 8px solid !important;\n  -webkit-transform: rotate(45deg);\n          transform: rotate(45deg);\n  border-radius: 4px;\n}\n\n.MsP9J._19nmq::after {\n  border-color: var(--bg-table-dark) !important;\n  \n}\n\n._2tsyJ {\n  position: relative;\n  padding: 8px 12px;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 18px;\n  border-radius: 8px;\n  background: white;\n  color: var(--text-dark);\n  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.20);\n          box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.20);\n}\n\n.MsP9J._19nmq ._2tsyJ {\n  background: var(--bg-table-dark);\n  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.20);\n          box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.20);\n  color: white;\n}\n\n.I77AT, .yFnEP, ._3oKoP {\n  bottom: -1px;\n}\n\n.Bn0fo, ._cCn8, .PrYHf {\n  top: -1px;\n}\n\n.I77AT, .Bn0fo {\n  left: -50%;\n  margin-right: -10px;\n}\n\n._3oKoP, .PrYHf {\n  left: 50%;\n  margin-left: -10px;\n}\n\n._2un3n, ._303Cv {\n  -webkit-transform: translateY(calc(-50% + 13px));\n          transform: translateY(calc(-50% + 13px))\n}\n\n._166ER, .ju_cM {\n  -webkit-transform: translateY(calc(50% - 13px));\n          transform: translateY(calc(50% - 13px))\n}\n", ""]);

// Exports
exports.locals = {
	"tooltipWrapper": "MsP9J",
	"dark": "_19nmq",
	"tooltip": "_2tsyJ",
	"topLeft": "I77AT",
	"top": "yFnEP",
	"topRight": "_3oKoP",
	"bottomLeft": "Bn0fo",
	"bottom": "_cCn8",
	"bottomRight": "PrYHf",
	"rightTop": "_2un3n",
	"leftTop": "_303Cv",
	"rightBottom": "_166ER",
	"leftBottom": "ju_cM"
};