import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import IconButton from 'material-ui/IconButton';
import ContentFilterList from 'material-ui/svg-icons/content/filter-list';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import Popover from 'material-ui/Popover';
import Dialog from '../../Common/Dialog';
import cx from 'classnames';
import s from './Filter.scss';
import RaisedButton from '../../Common/RaisedButton';
import CustomTooltip from './../../Common/CustomTooltip';
import RoundActionBtn from './../../Common/RoundActionBtn';
import FilterButton from '../../Common/FilterButton';

import Dashboard from './../Dashboard';
import { MAX_PORTS_FOR_FILTER, makeNumberIfValid } from './common';
import { convertToSameTimeZ } from '../../../core/api/api';
import Formsy from "formsy-react";


const MAX_STABLE_TABS = 3;
const SHRINK_WIDTH = 110;
const FULL_WIDTH = 130;

class Filter extends Component {

  static defaultProps = {
    container: Popover,
    tabsEnabled: true,
    saveLabel: 'SAVE AND APPLY',
    defaultValues: {},
    showMy: false,
    type: 'cargo',
    page: '', //need to use one set of filters on different pages
    shouldSearchDefault: true,
    fetchOnOpen: false,
    prepareDataToServer: (data) => data,
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      activeTab: null,
      now: new Date(),
      isOpenDashboard: false,
      nameDashboard: '',
      isExcludedDashboard: false,
      showedTabs: false,
      filters: [],
      filterValues: null,
      tabId: null,
      MAX_TABS: MAX_STABLE_TABS
    };

    this.tabIsClosing = false;

    this.formValues = { ...props.defaultValues };
    if (this.formValues.openFrom && !this.formValues.openFrom.getDate) {
      this.formValues.openFrom = new Date(this.formValues.openFrom);
    }
    if (this.formValues.readinessDate && !this.formValues.readinessDate.getDate) {
      this.formValues.readinessDate = new Date(this.formValues.readinessDate);
    }
    if (this.formValues.cancellingDate && !this.formValues.cancellingDate.getDate) {
      this.formValues.cancellingDate = new Date(this.formValues.cancellingDate);
    }
    if (this.formValues.openUntil && !this.formValues.openUntil.getDate) {
      this.formValues.openUntil = new Date(this.formValues.openUntil);
    }
    if (props.onlyMy) {
      delete this.formValues.onlyMy;
      delete this.formValues.onlyMyCheckbox;
    }

    const cleaned = {};
    Object.keys(this.formValues).forEach((key) => {
      const value = this.formValues[key];
      if (key.indexOf('Checkbox') !== -1 && key !== 'region' && key !== 'unloadingPortRegion' && key !== 'loadingPortRegion' && value) {
        cleaned[key] = value;
      } else if (value && key !== 'region' && key !== 'unloadingPortRegion' && key !== 'loadingPortRegion' && this.formValues[key + 'Checkbox']) {
        cleaned[key] = value;
      }
    });

    if (Object.keys(cleaned).length !== 0) {
      if (this.state.filters.length && this.state.filters[0].type === 'deckCargoFilter') {
        this.state.cargoFilterDirty = true;
      }
      if (this.state.filters.length && this.state.filters[0].type === 'deckVesselFilter') {
        this.state.vesselFilterDirty = true;
      }
    }
  }

  componentWillUnmount() {
    if (this._observer && this.refs._filtersBody) {
      this._observer.unobserve(this.refs._filtersBody);
    }
  }

  async componentDidMount() {
    const user = this.props.user;
    if (!user) {
      this.handleAddTab();
      this.props.handleFilter({});
      return;
    }
      await this.fetchFilters();
  }

  componentDidUpdate(prevProps){
    if(prevProps.tabId !== this.props.tabId && this.props.setTabId){
      if(!this.props.tabId) this.setState({
        [`${this.state.filters[0].type}Dirty`]:false
      })
    }
  }

  async fetchFilters() {
    const user = this.props.user;
    return await this.props.fetchData()
      .then((filters) => {
        let possiblyActiveFilter;
        const tabId = this.props.lastFilterId || this.props.tabId;
        const defaultFilterId = user?.accountPreferences?.[`default_${this.props.dataFieldName}`];

        if (tabId) {
          possiblyActiveFilter = filters.find(f => f._id === tabId);
        }

        if (this.props.shouldSearchDefault && !possiblyActiveFilter && defaultFilterId) {
          possiblyActiveFilter = filters.find(f => f._id === defaultFilterId);
        }
        if (filters.length > 0) {
          this.setActiveTab(possiblyActiveFilter || filters[0], filters);
        }
        else {
          this.handleAddTab();
        }
        let filterIndicatorState = false;

        const handler = this.props.handleFilter;

        // have to keep channels and search because handlers accept whole filters object
        let defaultValues = {};
        if (this.props.defaultValues.search) {
          defaultValues.search = this.props.defaultValues.search;
        }
        if (this.props.defaultValues.channels) {
          defaultValues.channels = this.props.defaultValues.channels;
        }
        if (this.props.defaultValues.tags) {
          defaultValues.tags = this.props.defaultValues.tags;
        }
        if (this.props.defaultValues.query) {
          defaultValues.query = this.props.defaultValues.query;
        }
        if (possiblyActiveFilter)  {
          let cleanedFilter = cleanFilter({ ...possiblyActiveFilter[this.props.dataFieldName] }, this.props.defaultFilter());
          if (Object.keys(cleanedFilter).length > 0) {
            filterIndicatorState = true;
          }
          cleanedFilter = this.props.prepareDataToServer(cleanedFilter);
          handler({
            ...cleanedFilter,
            ...defaultValues
          });
        }
        else {
          handler(defaultValues);
        }

        if (filterIndicatorState) {
          this.setState({
            activeTab: possiblyActiveFilter,
            [`${this.state.filters[0].type}Dirty`]: filterIndicatorState,
          });
        }
      })
      .catch(error => {
        this.props.handleFilter && this.props.handleFilter(this.props.defaultValues || {});
        console.error(`Fetching ${this.props.dataFieldName} data is failed`, error)
      });
  }

  getStableTabsCount = () => {
    let MAX_TABS = MAX_STABLE_TABS;
    try {
      const { _header: tabs, _addTabBtn: addBtn, state: { filters } } = this;
      if (!tabs || !addBtn) throw "Tabs header or add button isn't rendered";
      const computedStyle = window.getComputedStyle(tabs);
      const containerWidth = tabs.clientWidth;
      const paddingLeft = parseFloat(computedStyle.paddingLeft);
      const paddingRight = parseFloat(computedStyle.paddingRight);
      const addBtnWidth = addBtn.clientWidth;

      const width = parseFloat(containerWidth - paddingLeft - paddingRight - addBtnWidth);

      let tabWidth = SHRINK_WIDTH;
      MAX_TABS = Math.floor(width / tabWidth);
    } catch (error) {
      console.error(error);
    } finally {
      return MAX_TABS;
    }
  }

  calculateNewNameForDuplicate = (name, idToSkip) => {
    name = name.trim();
    let isExist;

    isExist = this.state.filters.some(elem => {
      return (elem[this.props.dataFieldName].filterName || elem.name) === name && (idToSkip !== elem._id);
    });

    if (!isExist) {
      return name;
    }

    const nameParts = name.trim().split(' ');
    let formattedName = '';
    let filterNumber = Number(nameParts[nameParts.length - 1]) || 0;
    let partsWithoutNumber;

    if (Number.isNaN(Number(nameParts[nameParts.length - 1]))) {
      partsWithoutNumber = nameParts;
    } else {
      partsWithoutNumber = nameParts.slice(0, -1);
    }

    formattedName = partsWithoutNumber.join(' ');

    if (!Number.isNaN(Number(nameParts[nameParts.length - 1]))) {
      filterNumber = Number(nameParts[nameParts.length - 1]);
    } else {
      filterNumber = '';
    }

    return this.calculateNewNameForDuplicate(`${formattedName} ${filterNumber + 1}`, idToSkip);
  }

  getFilterNumber = name => {
    return Number(name.split(' ')[1]);
  }

  handleAddTab = async () => {
    const newFilter = {
      _id: (Math.random() * 10000 | 0) + "",
      [this.props.dataFieldName]: {
        ...this.props.defaultFilter(),
        filterName: 'New 1',
      },
    };

    const calculateDefaultTabName = () => {
      let existedFilters = [];
      const dataFieldName = this.props.dataFieldName;

      if (this.state.filters) {
        existedFilters = [...this.state.filters];
        // sort filters in ascending order by their filterName numbers
        existedFilters.sort((a, b) => {
          if (this.getFilterNumber(a[dataFieldName].filterName) < this.getFilterNumber(b[dataFieldName].filterName)) {
            return -1;
          }
        });
      }

      if (existedFilters.length) {
        return this.calculateNewNameForDuplicate(newFilter[dataFieldName].filterName);
      }

      return newFilter[this.props.dataFieldName].filterName;
    }

    newFilter[this.props.dataFieldName].filterName = calculateDefaultTabName();

    this.setActiveTab(newFilter, this.state.filters.concat([newFilter]));
  };

  setActiveTab(filter, filters) {
    const prev = this.state.filters.find(filter => filter._id === this.state.tabId);
    if (prev) {
      prev[this.props.dataFieldName] = this.state.filterValues;
    }

    return new Promise((res, rej) => {
      this.setState({
        tabId: filter._id,
        filters: this.reorderTabs(filter._id, filters),
        filterValues: filter[this.props.dataFieldName],
      }, () => {
        res(filter);
        /* const observableNode = this.refs._filtersBody
        console.log(observableNode && observableNode.scrollHeight, window.innerHeight);
        if (observableNode && observableNode.scrollHeight >= window.innerHeight) {
          observableNode.parentNode.style.top = '0px';
        } */
      });
    });
  }

  toggleShowTabs = () => {
    this.setState({
      showedTabs: !this.state.showedTabs
    })
  }

  handleToggle = async (e = {}) => {
    const anchorEl = e.currentTarget || this.state.anchorEl;
    if (!this.state.open && this.props.fetchOnOpen) {
      await this.fetchFilters();
    }
    this.setState(
      {
        open: !this.state.open,
        anchorEl,
        now: this.props.showMy ? new Date() : undefined,
      },
      () => {
        // save maximum tabs count based on header and SHRINK/FULL_WIDTH of each tab, header's paddings and add btn
        const MAX_TABS = this.getStableTabsCount();
        this.setState({
          MAX_TABS
        })
        const observableNode = this.refs._filtersBody;
        if (observableNode) {
          this._observer = new ResizeObserver(entries => {
            const { target } = entries[0];
            const { anchorEl } = this.state;
            const node = observableNode || target.classList.contains(s.filters) ? target : target.querySelector(s.filters);
            const parent = node.parentNode || target;
            const top = parseFloat((anchorEl || parent).getBoundingClientRect().top) + parseFloat(anchorEl.offsetHeight);
            if (node.scrollHeight + top >= window.innerHeight) {
              parent.classList.add(s.fixed_top);
            }
            else {
              parent.classList.remove(s.fixed_top);
            }
          })
          this._observer.observe(observableNode);
        }
      }
    );
  };

  handleClose = (e) => {
    this.setState(() => ({
      open: false,
      showedTabs: false,
    }),
    () => {
      if (this._observer && this.refs._filtersBody) {
        this._observer.unobserve(this.refs._filtersBody);
      }
    }
    );
  };

  handleCloseDialog = () => {
    this.setState({ isOpenDashboard: !this.state.isOpenDashboard });
  };

  handleCloseFilterTab = closingFilterId => {
    if (this.state.filters.length === 1) {
      return;
    }

    const removalIndex = this.state.filters.findIndex(f => f._id === closingFilterId);
    const nextFilters = this.state.filters.filter(f => f._id !== closingFilterId);

    const currentTab = this.state.filters.find(f => f._id === this.state.tabId);
    const nextTab = nextFilters[Math.min(removalIndex, nextFilters.length - 1)];

    const { MAX_TABS } = this.state;

    const nextActiveTab = removalIndex < MAX_TABS ? nextTab : currentTab;

    this.setActiveTab(nextActiveTab, nextFilters);
    if (nextFilters.length <= MAX_TABS) {
      this.setState({
        showedTabs: false,
      });
    }

    if (closingFilterId.length === 24 && this.state.filters.length > 1) {
      this.props.deleteFilter(closingFilterId);
    }
  }

  handleSubmit = async (setDefault = false) => {
    let filter = this.props.defaultFilter();
    let data = this.props.defaultFilter();
    if (!setDefault) {
      filter = await this.handleSaveFilter();
      data = { ...this.state.filterValues };
    }

    // if (!this.isFormValid()) {
    //   return;
    // }
    const cleaned = cleanFilter(this.props.prepareDataToServer(data), this.props.defaultFilter());
    if (this.props.handleFilter) {
      // DIFERENTIATE ACTUAL SUBMIT FROM MOUNT IN TC ORDERS
      this.props.handleFilter({ ...cleaned, search: this.props.defaultValues.search, channels: this.props.defaultValues.channels, tags: this.props.defaultValues.tags }, true);
    }
    this.setState({ open: false, activeTab: Object.keys(cleaned).length !== 0 && filter?._id ? filter : null,  [`${this.state.filters[0].type}Dirty`]: Object.keys(cleaned).length !== 0 });
  };

  disableFilter = () => {
    if (this.props.setLastFilterId) {
      this.props.setLastFilterId(null);
    }
    if(this.props.setTabId){
      this.props.setTabId(null);
    }
    this.handleSubmit(true);
  };

  handleResetForm = () => {
    this.setState(state => ({
      filterValues: {
        ...this.props.defaultFilter(),
        filterName: state.filterValues.filterName,
      },
    }));
  };

  updateFilterField(name, value) {
    this.setState((state) => {
      const newValues = {
        ...state.filterValues,
        [name]: value,
      };
      if ((
          (name === 'openPorts' && value.length && newValues.useViableVessels)
          || (name === 'useViableVessels' && value && newValues.openPorts?.length)
          ) && !newValues.openFrom && !newValues.openUntil) {
        const from = new Date();
        const to = new Date();
        from.setDate(from.getDate() + 2);
        to.setDate(to.getDate() + 9);
        newValues.openFrom = from;
        newValues.openUntil = to;
        newValues.openFromCheckbox = true;
      }
      return ({
        'filterValues': newValues,
      });
    });
  }

  handleGenericChange = (name, value, shouldUpdateCheckbox = true) => {
    if (name.indexOf('Checkbox') !== -1) {
      if (value) {
        // this.refs[name.replace('Checkbox', '')].focus();
      }
    } else if (shouldUpdateCheckbox) {
      // very weird check for 'non-empty' value
      if (
        (_.isArrayLike(value) && value.length > 0) ||
        !_.isEmpty(value) ||
        value
      ) {
        this.updateFilterField(name + 'Checkbox', true);
      }
    }
    if (
      name === 'readinessDate' &&
      this.refs.cancellingDate &&
      this.refs.cancellingDate.getValue() &&
      value > this.refs.cancellingDate.getValue()
    ) {
      this.updateFilterField('cancellingDate', null);
    }
    if (
      name === 'openFrom' &&
      this.refs.openUntil &&
      this.refs.openUntil.getValue() &&
      value > this.refs.openUntil.getValue()
    ) {
      this.updateFilterField('openUntil', null);
    }

    switch(name) {
      case 'openPorts':
      case 'loadingPorts':
      case 'unloadingPorts':
      case 'currentPositions':
      case 'portDestination':
        this.updatePorts(name, value);
        break;
      case 'weightMin':
      case 'weightMax':
      case 'volumeMin':
      case 'volumeMax':
      case 'minDWT':
      case 'maxDWT':
      case 'minAge':
      case 'maxAge':
      case 'maxLoa':
      case 'maxDraft':
      case 'maxPositionAge':
      case 'maxBeam':
      case 'minSpeed':
      case 'minGearCapacity':
        this.updateFilterField(name, makeNumberIfValid(value));
        break;
      case 'cargoType':
        this.updateFilterField(name, value._id);
        break;
      case 'flags':
        this.updateFlags(name,value);
        break;
      default:
        this.updateFilterField(name, value);
    }
  }

  updatePortFromDashboard = (area, name, isExcluded = false) => {
    this.handleToggleDialog();
    setTimeout(() => {
      this.updatePorts(name, { ...area, areaParent: true, exclude: isExcluded });
    }, 0);
  };

  handleToggleDialog = (name = '', isExcluded = false) => {
    this.setState({
      isOpenDashboard: !this.state.isOpenDashboard,
      nameDashboard: name,
      isExcludedDashboard: isExcluded
    }, () => {
      this.handleToggle();
    });
  };

  removeFromPorts = (name, port) => {
    const ports = this.state.filterValues[name];
    const array = ports.filter(p => {
      if (p._id === port._id) {
        if (!p.maxDistance && !p.maxTime && !port.maxDistance && !port.maxTime) return false;
        if (port.maxDistance) return p.maxDistance !== port.maxDistance;
        if (port.maxTime) return p.maxTime !== port.maxTime;
        return true;
      }
      return true;
    });
    this.updateFilterField(name, array);
    this.updateFilterField(`${name}Checkbox`, !!array.length);
  }
  removeFromArray= (name, value) => {
    let array = (this.state.filterValues[name] || []).filter(a => a !== value) ;
    this.updateFilterField(name, array);
    this.updateFilterField(`${name}Checkbox`, !!array.length);
  }

  updatePorts = (name, value) => {
    if (!value) { return }

    if (value.length > 0 && typeof value[0] !== 'object') { return }
    let ports = this.state.filterValues[name] || [];

    if (value && !ports.find((port) => {
      if (port._id === value._id) {
        if (!port.maxDistance && !port.maxTime && !value.maxDistance && !value.maxTime) return true;
        if (value.maxDistance) return port.maxDistance === value.maxDistance;
        else if (value.maxTime) return port.maxTime === value.maxTime;
        return false;
      }
      return false;
    } )) {
      if (ports.length >= MAX_PORTS_FOR_FILTER) {
        return;
      }

      // temporar - because might extend to array of ports, now only one port can be selected
      // if(['portDestination','currentPositions'].indexOf(name) !== -1) {
      //   ports = [];
      // }

      let obj = {
        _id: value._id,
        name: value.name,
        areaParent: value.areaParent,
        maxDistance: value.maxDistance || 0,
        maxTime: value.maxTime || 0,
      };

      if (value.exclude) {
        obj.exclude = value.exclude;
      }

      let nextPorts = ports.concat(obj);
      // hack
      nextPorts = Array.from(new Set(nextPorts.flat(1)));

      this.updateFilterField(name, nextPorts);
      this.updateFilterField(`${name}Checkbox`, nextPorts.length > 0);
    }
  };

  updateFlags=(name, value)=>{
  let flags = this.state.filterValues[name] || [];
    if(!value || flags.find((f)=> f.country._id === value.country._id)) {
        return;
    }
    if (value.country.members) {
      flags = flags.filter(f => !(f.exclude === value.exclude && value.country.members.some(m => m._id === f.country._id)));
    } else if (flags.some(f => f.country.members && f.country.members.some(m => m._id === value.country._id && value.exclude === f.exclude))) {
      return;
    }
    flags = [...flags, value];
    this.updateFilterField(name, flags);
    this.updateFilterField(`${name}Checkbox`, flags.length > 0);
  }

  toggleActiveFilterTab = async (filterId) => {
    if (this.tabIsClosing) return;

    const filter = this.state.filters.find(filter => filter._id === filterId);
    this.setActiveTab(filter, this.state.filters);
  }

  /**
   * This function reorder provided filter array so that selected filter always visible
   * In case when selected tab already visible nothing happens.
   * Otherwise we place selected filter on last visible slot and move all other tabs after it.
   */
  reorderTabs = (filterId, filters) => {
    const selectedIndex = filters.findIndex(filter => filter._id === filterId);
    const { MAX_TABS } = this.state;
    // we should not reorder first three tabs
    if (selectedIndex < MAX_TABS) {
      return filters;
    }

    return filters.slice(0, MAX_TABS - 1).concat(
      filters[selectedIndex],
      ...filters.filter((_, i) => i !== selectedIndex).slice(MAX_TABS - 1),
    );
  }

  handleSaveFilter = () => {
    const formData = { ...this.state.filterValues };
    preSaveCleanFilter(formData);
    if(!formData.filterName || !formData.filterName.trim()) {  // i think we should check filterName  only when save button clicked
      this.refs.form.updateInputsWithError({filterName: 'required'});
      return;
    }
    if (!this.isFormValid()) {
      return;
    }

    convertToSameTimeZ(formData);

    let isNewFilter = false;

    // TODO: find better way to check for unsaved filters
    // maybe you may set flag isNewFilter=true for new filter, set it to false after filter was saved
    // or if you want to track unsaved filters, you may add unsaved flag, set it to true for new or changed filter
    const currentId = this.state.tabId;
    if (currentId.length !== 24) {
      isNewFilter = true;
    }

    const updateFilter = (response) => {
      const filter = response.data;

      const newFilters = this.state.filters.map(savedFilter => {
        if (savedFilter._id === currentId) {
          if (this.props.setLastFilterId) {
            this.props.setLastFilterId(filter._id);
          }
          if(this.props.setTabId){
            this.props.setTabId(filter._id)
          }
          return filter;
        }
        return savedFilter;
      })


      return this.setActiveTab(filter, newFilters);
    }

    formData.filterName = this.calculateNewNameForDuplicate(formData.filterName, this.state.tabId);
    if (isNewFilter) {
      return this.props.saveFilterToDB(formData.filterName, formData)
        .then(updateFilter)
        .catch(error => console.error(error));
    }
    return this.props.updateFilterInDB(this.state.tabId, formData)
      .then(updateFilter)
      .catch(error => console.error(error));
  }

  renderHeader = () => {
    if (!this.props.tabsEnabled) {
      return null;
    }
    let { filters } = this.state;
    const { MAX_TABS } = this.state;
    return (
      <div ref={el => this._header = el} className={cx(s.wrapper_header_title)}>
        <button
          ref={el => this._addTabBtn = el}
          className={s.btn_add_filter_tab}
          onClick={e => (this.handleAddTab(e), e.preventDefault())}>
            +
        </button>
        <div className={s.wrapper_tabs}>
          <div className={cx(s.wrapper_tabs_more_three)}>
            {filters.slice(0, MAX_TABS).map(filter =>
              <div
                className={cx(s.wrapper_title_tab, { [s.active]: filter._id === this.state.tabId })}
                style={{
                  width: filters.length > 2 ? `${SHRINK_WIDTH}px` : `${FULL_WIDTH}px`
                }}
                onClick={() => {this.toggleActiveFilterTab(filter._id)}}
              >
                <span
                  style={{
                    width: filters.length > 2 ? '68px' : '88px'
                  }}
                  title={ filter[this.props.dataFieldName].filterName || filter.name }
                  className={cx(s.name_filter, { [s.selected]: filter._id === this.state.activeTab?._id })}
                >
                  { filter[this.props.dataFieldName].filterName || filter.name }
                </span>
                <div className={cx(s.btn_close_wrapper, { [s.active]: filters.length > 1 })} title="delete">
                  <CloseIcon
                    style={{
                      width:'18px',
                      height:'18px'
                    }}
                    className={s.btn_close}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleCloseFilterTab(filter._id);
                    }}
                  />
                </div>
              </div>
            )}
            {
              filters.length > MAX_TABS &&
                <button
                  className={cx(s.btn_add_filter_tab, this.state.showedTabs ? s.active_header_title : null)}
                  onClick={e => (this.toggleShowTabs(e), e.preventDefault())}
                >
                  <MoreVertIcon color="#333" />
                </button>
            }
            {this.state.showedTabs &&
              <ul className={s.dropdown_tabs}>
                {filters.slice(MAX_TABS).map(filter =>
                  <li onClick={() => {
                    this.toggleActiveFilterTab(filter._id);
                    this.toggleShowTabs();
                  }}>
                    <span className={cx(s.name_filter, { [s.selected]: filter._id === this.state.activeTab?._id })} title={ filter[this.props.dataFieldName].filterName || filter.name }>
                      { filter[this.props.dataFieldName].filterName || filter.name }
                    </span>
                    <div className={cx(s.btn_close_wrapper, { [s.active]: filters.length > 1 })} title="delete">
                      <CloseIcon
                        style={{
                          width:'18px',
                          height:'18px'
                        }}
                        className={s.btn_close}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleCloseFilterTab(filter._id);
                        }}
                      />
                    </div>
                  </li>
                )}
              </ul>
            }
          </div>
        </div>
      </div>
    )
  }

  isFormValid = () => {
    const { form } = this.refs;
    form.submit();
    return form.state.isValid;
  };

  getForm =()=> {
    return this.refs.form;
  }

  render() {
    const { filterValues, tabId } = this.state;
    const { popoverProps = {}, filterCargo, renderFilter, classes = '',lastFilterId, setLastFilterId } = this.props;
    const Container = this.props.container;

    // this is very cheat to allow us to call onSubscribe hook in parent context
    // but to not bypass this handler over props
    let onSubscribe = undefined;
    let subscribeOnSubmit = (fn) => {
      onSubscribe = () => {
        return new Promise((res, rej) => {
          fn();
          setTimeout(res, 0);
        });
      };
    };

    let filterName = "Filter";
    /* if (this.state.filterValues && this.state[`${this.props.dataFieldName}Dirty`] && this.state.filters?.length) {
      const tabId = sessionStorage.getItem(`${this.props.page}${this.props.dataFieldName}TabId`);
      const activeFilter = this.state.filters.find(filter => filter?._id === tabId);
      if (activeFilter) {
        filterName = activeFilter.name;
      }
    } */
    if (this.state.activeTab?.[this.props.dataFieldName]?.filterName) {
      filterName = this.state.activeTab[this.props.dataFieldName].filterName;
    }
    const isFilterDirty = setLastFilterId ? !!this.state.activeTab && !!lastFilterId : this.state.filters[0] && this.state[`${this.state.filters[0].type}Dirty`]

    return (
      <div className={cx(s.root, filterCargo)}>
        <Dialog
          open={this.state.isOpenDashboard}
          bodyClassName={s.dialogBody}
          contentClassName={s.dialogContent}
          handleClose={this.handleToggleDialog}
          onRequestClose={this.handleToggleDialog}
        >
          <Dashboard
            updatePort={this.updatePortFromDashboard}
            toggleDialog={this.handleToggleDialog}
            nameDashboard={this.state.nameDashboard}
            name={this.state.nameDashboard}
            isExcluded={this.state.isExcludedDashboard}
          />
        </Dialog>
            <div className={cx({[s.dirty]: isFilterDirty}, s.filter_outer)}>
              {this.props.isOld ? (
              <RoundActionBtn
                dark={!this.props.isDark}
                tooltip={filterName}
                onClick={this.handleToggle}
                iconClassName={cx(
                    {[s.dirty]: isFilterDirty},
                    s.button_filter
                  )}
              >
                <ContentFilterList />
              </RoundActionBtn>
              ): (
            <FilterButton
              dark={!this.props.isDark}
              tooltip={filterName}
              onClick={this.handleToggle}
              isClearBtnVisible={false}
              onClear={async (e) => {
              e.stopPropagation();
              this.disableFilter();
              }}
              isDirty={isFilterDirty}
            />)
            }
            </div>

        <Container
          open={this.state.open}
          onRequestClose={this.handleClose}
          anchorEl={this.state.anchorEl}
          useLayerForClickAway
          canAutoPosition={false}
          animated={false}
          className={cx(s.filters_main_popover)}
          {...popoverProps}
        >
          <div ref="_filtersBody" className={cx(s.filters, s[this.props.type])}>
            <div>
              { this.renderHeader() }
            </div>
            <Formsy.Form ref="form">
            { filterValues &&
              renderFilter(
                tabId,
                filterValues,
                {
                  onChange: this.handleGenericChange,
                  removeFromPorts: this.removeFromPorts,
                  handleToggleDialog: this.handleToggleDialog,
                  subscribeOnSubmit,
                  getForm: this.getForm,
                  removeFromArray: this.removeFromArray
                }
              )
            }
            </Formsy.Form>
            <div className={cx(s.footer,{[s.old]:this.props.isOld})}>
              {this.props.isOld && <div>
                  <RaisedButton
                    label="Set to default"
                    secondary
                    onClick={async () => {
                      // call onSubmit hooks on parent components
                      onSubscribe && (await onSubscribe());
                      this.disableFilter();
                    }}
                  />
              </div>}
              
              <div>
                <RaisedButton
                  label="Clear"
                  secondary
                  onTouchTap={this.handleResetForm}
                />
                <RaisedButton
                  type="submit"
                  label={this.props.saveLabel}
                  primary
                  onClick={async () => {
                    // call onSubmit hooks on parent components
                    onSubscribe && (await onSubscribe());
                    this.handleSubmit();
                  }}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}




Filter.propTypes = {
  defaultValues: PropTypes.object,
  showMy: PropTypes.bool,
  onlyMy: PropTypes.bool,
  handleFilter: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['cargo', 'vessel']).isRequired,
  user: PropTypes.object,
};

function preSaveCleanFilter(data) {
  if (data.typeByPurpose === 'TANK') {
    data.gear = undefined;
    data.gearCheckbox = undefined;
    data.minHoldLength = undefined;
    data.minHoldLengthCheckbox = undefined;
    data.itfFitted = undefined;
    data.itfFittedCheckbox = undefined;
    data.openHatch = undefined;
    data.imoFitted = undefined;
    data.imoFittedCheckbox = undefined;
    data.boxShaped = undefined;
    data.boxShapedCheckbox = undefined;
  }
}

function cleanFilter(data, defaultValues = {}) {
  // const cleanData = safeDelete(data);
  delete data.typeCheckbox;
  delete data.type;
  data.weightMaxCheckbox = !!data.weightMinCheckbox;
  data.volumeMaxCheckbox = !!data.volumeMinCheckbox;
  data.maxDWTCheckbox = !!data.minDWTCheckbox;
  data.maxAgeCheckbox = !!data.minAgeCheckbox;
  data.maxMarketCheckbox = !!data.minMarketCheckbox;
  data.cancellingDateCheckbox = !!data.readinessDateCheckbox;
  data.openUntilCheckbox = !!data.openFromCheckbox;
  data.openPortCheckbox = !!data.openPortCheckbox;
  data.unloadingPortCheckbox = !!data.unloadingPortCheckbox;
  data.loadingPortCheckbox = !!data.loadingPortCheckbox;
  data.lastTradeDateToCheckbox = !!data.lastTradeDateFromCheckbox;
  data.minGearCapacityCheckbox = !!data.gearCheckbox;
  data.accountsCheckbox = !!data.accountsCheckbox;
  data.invoiceDateCheckbox = !!data.invoiceDateCheckbox;
  data.loadingPortsCheckbox = !!data.loadingPortsCheckbox;
  data.unloadingPortsCheckbox = !!data.unloadingPortsCheckbox;
  data.dueDateCheckbox = !!data.dueDateCheckbox;
  data.laycanDateCheckbox = !!data.laycanDateCheckbox;
  if (data?.openPorts?.length === 0) {
    data.openPorts = undefined
  }
  if (data?.portDestination?.length === 0) {
    data.portDestination = undefined
  }
  if (data?.flags?.length === 0) {
    data.flags = undefined
  }
  if (data?.currentPositions?.length === 0) {
    data.currentPositions = undefined
  }
  if (data.cancellingDate) {
    data.cancellingDate.setHours(23);
    data.cancellingDate.setMinutes(59);
    data.cancellingDate.setSeconds(59);
  }
  if (data.openUntil) {
    data.openUntil.setHours(23);
    data.openUntil.setMinutes(59);
    data.openUntil.setSeconds(59);
  }
  if (data?.delivery?.length === 0) {
    data.delivery = undefined;
  }
  if (data?.redelivery?.length === 0) {
    data.redelivery = undefined;
  }
  if (data?.flags?.length === 0) {
    data.flags = undefined;
  }
  if (data?.cargo?.length === 0) {
    data.cargo = undefined;
  }
  if (data?.typesBySize?.length === 0) {
    data.typesBySize = undefined;
  }
  if (data?.typesByPurpose?.length === 0) {
    data.typesByPurpose = undefined;
  }
  if (data?.loadingPorts?.length === 0) {
    data.loadingPorts = undefined;
  }
  if (data?.unloadingPorts?.length === 0) {
    data.unloadingPorts = undefined;
  }
  if (data?.cargoTypes?.length === 0) {
    data.cargoTypes = undefined;
  }
  if (data?.cargoNames?.length === 0) {
    data.cargoNames = undefined;
  }
  if (data?.transportation?.length === 0) {
    data.transportation = undefined;
  }
  if (data?.accounts?.length === 0) {
    data.accounts = undefined;
  }
  preSaveCleanFilter(data);
  // in case all filters will be controlled with model defined in advance
  /* Object.keys(data).forEach(key => {
    if (!defaultValues.hasOwnProperty(key)) {
      cleanData(key);
      return;
    }
  }) */
  const cleaned = {};
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (
      (value || (key === 'gear'))
      && key !== 'region'
      && key !== 'unloadingPortRegion'
      && key !== 'loadingPortRegion'
      && data[key + 'Checkbox']
      && key.indexOf('Exclude') < 0) {
      cleaned[key] = value;
    }
  });
  delete cleaned.filterName;
  return cleaned;
}

const safeDelete = data => name => {
  if (data && data[name]) {
    delete data[name];
  }
}

export default withStyles(s)(Filter);


export function FilterDialogContainer({ open, onRequestClose, ...props }) {
  return <Dialog open={open} handleClose={onRequestClose} {...props} {...FilterDialogContainer.styles} />;
}

FilterDialogContainer.styles = {
  bodyStyle: {
    padding: '0',
    color: 'var(--text-dark333)',
  },
  contentStyle: { width: 'max-content' },
  titleStyle: { backgroundColor: 'var(--stroke-light-gray1)' },
}

