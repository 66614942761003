import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAccountFilters, saveLastFilters } from '../../../actions/login';


export class SaveFilterWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterToSave: {},
      lastFilters: {},
    };
    if (props.lastFilters) {
      this.state.filterToSave = props.lastFilters[props.lastFilterFieldName] || {};
      this.state.lastFilters = props.lastFilters;
    }
  }

  clearFilters = async ()=> {
    const newLastFilter = { [this.props.lastFilterFieldName]: {filterId:null} };
    this.setState({ filterToSave: newLastFilter[this.props.lastFilterFieldName] || {}, lastFilters: this.props.lastFilters || {} });
    await this.props.saveLastFilters(newLastFilter);
  }

  setLastFilterId = (id) => {
    const newLastFilter = { [this.props.lastFilterFieldName]: { ...this.state.filterToSave, filterId: id } };
    this.props.saveLastFilters(newLastFilter);
    this.setState({ filterToSave: newLastFilter[this.props.lastFilterFieldName] || {}, lastFilters: this.props.lastFilters || {} });
  }

  getLastFilters = async () => {
    try {
      if (Object.keys(this.props.lastFilters).length === 0) {
        await this.props.getAccountFilters();
      }
      this.setState({ filterToSave: this.props.lastFilters[this.props.lastFilterFieldName] || {}, lastFilters: this.props.lastFilters || {} });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastFilterFieldName !== this.props.lastFilterFieldName) {
      this.setState({ filterToSave: this.state.lastFilters[this.props.lastFilterFieldName] || {} });
    }
    if (prevProps.lastFilters !== this.props.lastFilters) {
      this.setState({ lastFilters: this.props.lastFilters || {}, filterToSave: this.props.lastFilters[this.props.lastFilterFieldName] || {} });
    }
  }

  async componentDidMount() {
    if(this.props.setClearAllFilters){
      this.props.setClearAllFilters(this.clearFilters)
    }
    await this.getLastFilters();
  }

  handleWrapperAnalyticsDates = (handler, field) => {
    return async (_, value) => {
      const newLastFilter = { [this.props.lastFilterFieldName]: { ...this.state.filterToSave, [field]: value } };

      await this.props.saveLastFilters({ ...newLastFilter });
      await handler(value);
      this.setState({ filterToSave: this.props.lastFilters[this.props.lastFilterFieldName] || {}, lastFilters: this.props.lastFilters || {} });
    };
  }

  handleDateRangeChange = (handler)=>{
    return async (_, value) => {
      const newLastFilter = { [this.props.lastFilterFieldName]: { ...this.state.filterToSave, ...value } };

      await this.props.saveLastFilters({ ...newLastFilter });
      if(handler && typeof handler === 'function') await handler(value);
      this.setState({ filterToSave: this.props.lastFilters[this.props.lastFilterFieldName] || {}, lastFilters: this.props.lastFilters || {} });
    };
  }

  handleThemeChange=(handler)=>{
    return async (value)=>{
      const newLastFilter = { [this.props.lastFilterFieldName]: { ...this.state.filterToSave, ...value } };
      if(handler && typeof handler === 'function') await handler(value);
      await this.props.saveLastFilters({ ...newLastFilter });
      this.setState({ filterToSave: this.props.lastFilters[this.props.lastFilterFieldName] || {}, lastFilters: this.props.lastFilters || {} });
    }
  }

  handlerWrapper = (handler, field, isFilterField = false, additionalFields = null) => {
    return async (value) => {
      if (field === 'tags') {
        if (!value.tags) {
          value.tags = { condition: [] };
        }
      }
      let newLastFilter;
      if (isFilterField) {
        if (additionalFields !== null) {
          newLastFilter = { [this.props.lastFilterFieldName]: { ...this.state.filterToSave, [field]: value[field], ...additionalFields.reduce((acc, f) => { acc[f] = value[f]; return acc; }, {}) } };
        } else {
          newLastFilter = { [this.props.lastFilterFieldName]: { ...this.state.filterToSave, [field]: value[field] } };
        }
      } else {
        newLastFilter = { [this.props.lastFilterFieldName]: { ...this.state.filterToSave, [field]: value } };
      }
      await this.props.saveLastFilters(newLastFilter);
      await handler(value);
      this.setState({ filterToSave: this.props.lastFilters[this.props.lastFilterFieldName] || {}, lastFilters: this.props.lastFilters || {} });
    };
  }
  renderChildrenWithProps = (children) => {
      const childrenWithProps = children.map(child => {
        if (child && child.props) {
          const name = child?.props?.name;
          switch (name) {
            case 'search' :
            return React.cloneElement(child, { query: this.state.filterToSave.search, handleSearch: this.handlerWrapper(child.props.handleSearch, 'search') });
            case 'q' :
            return React.cloneElement(child, { q: this.state.filterToSave.q, handleSearch: this.handlerWrapper(child.props.handleSearch, 'q') });
            case 'endDate' :
            return React.cloneElement(child, { value: this.state.filterToSave.endDate || child.props.value, onChange: this.handleWrapperAnalyticsDates(child.props.onChange, 'endDate') });
            case 'startDate' :
            return React.cloneElement(child, { value: this.state.filterToSave.startDate || child.props.value, onChange: this.handleWrapperAnalyticsDates(child.props.onChange, 'startDate') });
            case 'dateRange' :
            return React.cloneElement(child, { onChange: this.handleDateRangeChange(child.props.onChange)});
            case 'tags' :
            return React.cloneElement(child, { filters: { ...child.props.filters, tags: this.state.filterToSave.tags }, tags: this.state.filterToSave.tags, handleSearch: this.handlerWrapper(child.props.handleSearch, 'tags', true) });
            case 'sort' :
            return React.cloneElement(child, { sort: this.state.filterToSave.sort || child.props.sort, handleSort: this.handlerWrapper(child.props.handleSort, 'sort') });
            case 'filter' :
            return React.cloneElement(child, { lastFilterId: this.state.filterToSave.filterId, setLastFilterId: this.setLastFilterId });
            case 'channels' :
            return React.cloneElement(child, { filters: { ...child.props.filters, channels: this.state.filterToSave.channels }, handleFilter: this.handlerWrapper(child.props.handleFilter, 'channels', true) });
            case 'type' :
            case 'types' :
            case 'status':
            return React.cloneElement(child, { filters: { ...child.props.filters, [name]: this.state.filterToSave[name] }, handleFilter: this.handlerWrapper(child.props.handleFilter, name, true) });
            case 'owner' :
            return React.cloneElement(child, { filters: { ...child.props.filters, owner: this.state.filterToSave.owner }, handleFilter: this.handlerWrapper(child.props.handleFilter, 'owner', true) });
            case 'scopes' :
            return React.cloneElement(child, { filters: { ...child.props.filters, scopes: this.state.filterToSave.scopes, read: this.state.filterToSave.read, private: this.state.filterToSave.private, favorite: this.state.filterToSave.favorite, attached: this.state.filterToSave.attached }, handleFilter: this.handlerWrapper(child.props.handleFilter, 'scopes', true, ['read', 'private', 'favorite', 'attached']) });
            case 'receivedAt' :
            return React.cloneElement(child, { filters: { ...child.props.filters, fields: { ...this.state.filterToSave.receivedAt } }, handleFilter: this.handlerWrapper(child.props.handleFilter, 'receivedAt', true) });
            case 'theme':
            return React.cloneElement(child,{changeTheme: this.handleThemeChange(child.props.changeTheme) });
            case 'groupBy':
              return React.cloneElement(child,{ saveFilterHandler: this.handlerWrapper(() => undefined , 'groupBy') });
            default :
            return child;
          }
        }
        return child;
      });
    return childrenWithProps;
  }

  render() {
    const { children, style } = this.props;
    return <div className={this.props.className} style={{ ...style }}>{this.renderChildrenWithProps(children)}</div>;
  }

}

export default connect(state => ({ lastFilters: state.login.lastFilters }), { getAccountFilters, saveLastFilters },)(SaveFilterWrapper);
