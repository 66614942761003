import { number } from "../../core/format";
import { gearLabels } from '../Monitor/Edit/config';

export const vesselFormatters = {
  fittedText,

  gearV2: {
    shortText({ geared, gearV2 }) {
      const result = gearV2
          ?.summary
          ?.filter(x => x.type)
          .map(x => `${x.type} - ${x.quantity || ''} - ${x.capacity || ''}`).join(', ')

      return result || (has(geared) ? geared ? 'geared' : 'gearless' : '')
    },
    longText({ geared, gearV2 }) {
      return gearV2 && vesselFormatters.gearV2._lines({ geared, gearV2 }).join('; \n')
    },
    _lines({ geared, gearV2 }) {
      const result = gearV2
          ?.summary
          ?.filter(x => x.type)
          .map(x => (
              [
                `${gearLabels[x.type]}${has(x.quantity) ? ` ${x.quantity}x` : ''}`,
                minMaxText('capacity', x.capacityMin, x.capacityMax, 'mt'),
                has(x.outreach) && `outreach ${number(x.outreach, '', 'm', 2, 0, '---', '')}`,
              ].filter(Boolean).join(', ')
          ))

      return result?.length
          ? result
          : has(geared) ? geared ? ['Geared'] : ['Gearless'] : []
    },
  },

  grabV2: {
    shortText({ grabbed, grabV2 }) {
      const result = grabV2
          ?.summary
          ?.filter(x => x.type)
          .map(x => `${x.type} - ${x.quantity || ''} - ${x.volume || ''}`).join(', ')

      return result || (has(grabbed) ? grabbed ? 'fitted' : 'not fitted' : '')
    },
    longText({ grabbed, grabV2 }) {
      return grabV2 && vesselFormatters.grabV2._lines({ grabbed, grabV2 }).join('; \n')
    },
    _lines({ grabbed, grabV2 }) {
      const result = grabV2
          ?.summary
          ?.filter(x => x.type)
          .map(x => (
              [
                `${x.type}${has(x.quantity) ? ` ${x.quantity}x` : ''}`,
                minMaxText('volume', x.volumeMin, x.volumeMax, 'cbm'),
              ].filter(Boolean).join(', ')
          ))

      return result?.length
        ? result
        : has(grabbed) ? grabbed ? ['fitted'] : ['not fitted'] : []
    },
  },

  pumps: {
    // only cargo, ballast isn't needed
    shortText({ pumps }) {
      const result = pumps
          ?.cargo
          ?.filter(x => x.type)
          .map(x => `${x.type} - ${x.quantity || ''} - ${x.capacity || ''}`).join(', ')

      return result || ''
    },
    longText({ pumps }) {
      const cargo = vesselFormatters.pumps._lines({ pumps: pumps.cargo }).join('; ')
      const ballast = vesselFormatters.pumps._lines({ pumps: pumps.ballast }).join('; ')
      return [
        cargo && `cargo: ${cargo}`,
        ballast && `ballast: ${ballast}`,
      ].filter(Boolean).join('; \n')
    },
    _lines({ pumps }) {
      return (pumps || [])
        .filter(x => x.type)
        .map(x => (
            [
              `${x.type}${has(x.quantity) ? ` ${x.quantity}x` : ''}`,
              has(x.capacity) && `capacity ${number(x.capacity, '', 'm3/hour')}`,
            ].filter(Boolean).join(', ')
        ))
    },
  },

  tcRate: {
    text({ status }) {
      if (!status || !status.value) return null
      return number(status.value, status.currency || '$', '', 0)
    },
  }
}

function has(value) {
  return value !== null && value !== undefined && value !== ''
}

function hasSome(...values) {
  return values.some(has)
}

function hasEvery(...values) {
  return values.every(has)
}

function minMaxText(title, min, max, unit) {
  return (
    hasSome(min, max) &&
    `${title} ${
      hasEvery(min, max)
        ? 'min/max'
        : has(min)
          ? 'min'
          : 'max'
    } ${
      hasEvery(min, max)
        ? `${number(min)}/${number(max, '', unit)}`
        : has(min)
          ? number(min, '', unit)
          : number(max, '', unit)
    }`
  )
}

function fittedText(fitted) {
  return has(fitted)
    ? (fitted ? 'fitted' : 'not fitted')
    : null
}
