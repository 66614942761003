import React, { Component } from 'react';
import { parseErrorsArray } from '../utils';
import FreightView from './FreightView';
import MainPart from './MainPart';
import { Commission, Contract, LDRatesView, Terms, Wrapper } from './../shared/preview';
import RaisedButton from '../../Common/RaisedButton';
import SpinnerButton from '../../Common/SpinnerButton';
import VesselRequirementsPreview, { hasVesselRequirements } from "../../Cargo/Preview/VesselRequirementsPreview";

export class DocumentPreview extends Component {
  render() {
    const { request, errors = [] } = this.props;

    const errorsTable = parseErrorsArray(errors);

    return (
      <Wrapper>
        <MainPart request={request} errorsTable={errorsTable} />
        <FreightView
          freights={request.freights}
          charges={request.charges}
          demurrage={{ demurrage: request.demurrage, opts: request.demurrageOpts }}
          detention={{ detention: request.detention, opts: request.detentionOpts }}
        />
        <LDRatesView loading={request.ldRates?.loading} discharging={request.ldRates?.discharging} />

        {hasVesselRequirements(request.vesselRequirements) && (
          <div className="row">
            <div className="col preview_label" style={{ lineHeight: '28px' }}>VESSEL REQUIREMENTS</div>
            <div className="preview_value grow">
              <VesselRequirementsPreview vesselRequirements={request.vesselRequirements} />
            </div>
          </div>
        )}

        <Terms
          terms={request.terms}
        />
        <Contract contractId={request.contract} />
        <Commission deductible={request.deductible} commission={request.comm} pus={request.pus} />
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '24px' }}>
          <RaisedButton
            label="BACK TO EDIT"
            disableTouchRipple
            disableFocusRipple
            secondary
            onClick={this.props.onClose}
            style={{
              borderRadius: '15px',
              marginRight: '8px',
            }}
            buttonStyle={{
              borderRadius: '15px',
            }}
          />
          <SpinnerButton
            label="SAVE"
            disableTouchRipple
            disableFocusRipple
            primary
            disabled={errors?.length}
            onClick={() => this.props.onSend(request)}
            style={{
              borderRadius: '15px',
            }}
            buttonStyle={{
              borderRadius: '15px',
            }}
          />
        </div>
      </Wrapper>
    )
  }
}

export default DocumentPreview;
