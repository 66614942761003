import React, {Component} from 'react';
import cx from "classnames";
import s from "../Monitor/TableNew.scss";
import {Pane} from "../Common/SplitPane";
import styled, {css} from "styled-components";
import NavigationPopover from "../Common/NavigationPopover";
import ChipsAutocomplete from "../Common/Tags/ChipsAutocomplete";
import RaisedButton from "../Common/RaisedButton";
import Sort from "../Common/Sort";
import {connect} from "react-redux";
import {
  getContactList,
  getContactDetails,
  createContact,
  checkContact,
  checkAll,
  deleteContacts,
  toggleFavorites,
  mergeContacts,
  getCounters,
  getHeatmap,
} from "../../actions/contacts";
import { getProfile, getAccountFilters } from "../../actions/login";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import CustomTooltip from "../Common/CustomTooltip";
import Col from "../Monitor/RowBlocks/Col";
import {VesselSelector} from "../Monitor/VesselSelector";
import { RowWrapper, Text } from '../Contracts/shared/styled';
import CustomCheckbox from "../Common/CustomCheckbox";
import VirtualList from "../Common/VirtualList";
import history from "../../core/history";
import {Avatar} from "./Avatar";
import ConfirmDialog from "../Common/ConfirmDialog";
import WithTooltip from "../Common/WithTooltip";
import Link from "../Link";
import TagsView from "../Common/Tags/TagsView";
import ContactPersonIcon from "../Icons/ContactPersonIcon";
import ContactGroupIcon from "../Icons/ContactGroupIcon";
import {PlusButtonBlue} from "../Common/AddButton";
import IconButton from "material-ui/IconButton";
import {muteEvent} from "../../core/utils";
import Search from "../Common/Search";
import SaveFilterWrapper from '../Monitor/Filter/SaveFilterWrapper';
import ContactTypeFilter from "./ContactTypeFilter";
import DialogHalfScreen from "../Common/DialogHalfScreen";
import MergeContactView from "./MergeContactView";
import SortContacts from '../Common/SortContacts';
import EmptyList from '../Common/EmptyList';
import { areFiltersApplied } from '../helpers';
import EmptyListResult from '../Common/EmptyListResults';
import Loader from '../Common/Loader';

export const MultiValueTd = styled(({ className, values, renderValue = (v) => v, tooltipProps = { horizontalPosition: "left" } }) => {
  if (!values || !values.length) {
    return <div></div>;
  }
  return <div className={className}><div>{renderValue(values[0])}</div>{values.length > 1 ? (<WithTooltip {...tooltipProps} tip={<div className={'tooltip-default dark'}>{values.slice(1).map((v) => <div>{renderValue(v)}</div>)}</div>}><div>+{values.length - 1}</div></WithTooltip>) : null}</div>;
})`
  display: flex;
  >div:nth-child(1) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  >div:nth-child(2) {
    margin-left: 4px;
    color: var(--text-links, #4380C7);
  }
`
const sortFields =  {
  'name': {
    field: 'name', value: 1, label: 'Name',
  },
  'date': {
    field: 'date',
    value: -1,
    label: 'Created',
  },
};
export const ContactsHeader = styled(function ({ filters, folder = 'PERSONS', toggleTheme, dark, handleSort, handleFilter, className, user, buttons, sort, sortProps = {}, counters={}, setClearAllFilters }, ){
  return <div className={cx(className, "align-items-center", "w-100", "space-between")}>
    <div className="flex">
        <SaveFilterWrapper setClearAllFilters={setClearAllFilters} style={{display: "flex"}} lastFilterFieldName={`${folder}Filters`}>
        <ChipsAutocomplete
          key={folder}
          filters={filters}
          tags={filters.tags}
          handleSearch={handleFilter}
          entity="cargoRequest"
          className={'contacts-tags-autocomplete'}
          name="tags"
        />
{/*        <Search
          query={filters.q}
          handleSearch={(q) => {
            handleFilter({ ...filters, q });
          }}
          isNarrow
          name="q"
          className={'contacts-tags-autocomplete'}
        />*/}
          <SortContacts values={sortFields} sort={sort} handleSort={handleSort} {...sortProps} style={{marginLeft: "8px"}} name="sort" />
          {folder === 'persons' ? <ContactTypeFilter name={"types"} filters={filters} handleFilter={handleFilter} style={{marginLeft: "8px"}} counters={counters} /> : null}
        </SaveFilterWrapper>
    </div>

    <div style={{ marginLeft: '8px' }} className="align-items-center" >
      <PersonGroupSwitcher folder={folder} />
      {buttons}
    </div>
  </div>;
})`
  > div:nth-child(3) {
    max-width: 217px;
  }
  div.contacts-tags-autocomplete {
    width: 160px;
  }
  @media (min-width: 1200px) {
    div.contacts-tags-autocomplete {
      width: 180px;
    }
  @media (min-width: 1300px) {
    div.contacts-tags-autocomplete {
      width: 220px;
    }
  }
  @media (min-width: 1440px) {
    div.contacts-tags-autocomplete {
      width: 280px;
    }
  }
`

const ContactsAltHeader = styled(function ({ className, onDelete, onToggleFavorite, onMerge, folder, data, ...props }) {
  const selectedCount = data.filter(c => c.selected).length;
  const mergeAvailable = selectedCount > 1 && folder === 'persons';
  return <div className={className}>
    {onToggleFavorite ? <CustomTooltip tooltip={"Favorite"}><span onClick={onToggleFavorite} className="material-icons">star</span></CustomTooltip> : null}
    <CustomTooltip tooltip={"Delete"}><span onClick={onDelete} className="material-icons">delete</span></CustomTooltip>
    {mergeAvailable ? <CustomTooltip tooltip={"Merge"}><span onClick={onMerge} className="material-icons contact-merge-icon">merge</span></CustomTooltip> : null}
  </div>;
})`
  display: flex;
  >div{
    cursor: pointer;
  }
  > div + div {
    margin-left: 8px;
  }
  .material-icons {
    color: #787878;
  }
  .contact-merge-icon {
    transform: rotate(90deg);
  }
`

export const BaseContactRowWrapper = styled(RowWrapper)`
  .${s.ref_checkbox}.custom-checkbox {
    margin-right: 0px;
    width: 16px !important;
    height: 16px !important;
    input {
      width: 16px !important;
      height: 16px !important;
    }
    svg {
      margin-top: 1px;
      margin-left: 1px;
      width: 14px !important;
      height: 14px !important;
    }
    margin-top: -2px !important;
    margin-bottom: 1px !important;

  }
  .${s.text} {
    justify-content: space-between;
    padding: 10px 8px 7px 0px;
    color: var(--default-dark-text);
    ${props => props.header && css`
        font-weight: 400;
        color: var(--text-medium);
      `
    }
  }
  > div:first-child {
    width: 16px;
    min-width: 16px;
    margin-right: 9px;
    overflow: unset;
    padding: 10px 0px 9px 0px;
  }
  .tooltip-default {
    white-space: pre-line;
    max-height: 300px;
    max-width: 478px;
    overflow: auto;
  }
  .tooltip_position {
    right: -13px !important;
  }
  &.selected.selected {
    background: var(--bg-bidding, #E7F6FF);
  }
  &.new.new {
    background: var(--bg-new-offer, #F1F9FF);
  }
`;

export const StyledRowWrapper = styled(BaseContactRowWrapper)`

  > div:nth-child(2) {
    flex: 2;
  }
  > div:nth-child(3){
    width: 108px;
    min-width: 108px;
  }

  > div:nth-child(4) {
    flex:1;
  }
  > div:nth-child(5) {
    flex: 1;
  }
  > div:nth-child(6) {
    width: 60px;
    min-width: 60px;
  }
  .custom-checkbox.custom-checkbox {
    margin-top: 5px !important;
  }
  &.readonly {
    >div:nth-child(1) {
      display: none;
  }
  }
  a {
    text-decoration: underline;
  }
`;

const TableHeader = styled(({ className, checkAll, rows, header = true, background = 'var(--bg-light-grey)', readonly, ...props }) => {
  return (
    <div className={cx(className)}>
      <StyledRowWrapper noHover h="48px" header={header} className={cx(readonly && 'readonly')}>
        <Col>
          <VesselSelector style={{ maxWidth: '26px', marginTop: '-4px', overflow: 'unset' }} vertical refList={[]} handleCheck={checkAll} vessels={rows} />
        </Col>
        <Col>
          <Text>
            NAME / SURNAME
          </Text>
          <Text>
            EMAIL
          </Text>
        </Col>
        <Col>
          <Text>
            NUMBER
          </Text>
        </Col>
        <Col>
          <Text>
            TAGS
          </Text>
        </Col>
        <Col>
          <Text>
            COMPANY
          </Text>
          <Text>
            POSITION
          </Text>
        </Col>
        <Col>
          <Text>
            CONTACT
          </Text>
        </Col>
      </StyledRowWrapper>
    </div>);
})`
  background:${(props) => props.background} ;
`

const StyledTable = styled.div`
  display: flex;
  height: ${props => props.height || '100%'};
  flex-direction: column;
`


const NoContacts = (props) => {
  if(props.areFiltersApplied) return <EmptyListResult onClear={props.handleClearFilters} />
  return <EmptyList maxTextWidth='400px' Icon='person' isMaterialIcon={true}
  title='No Contacts yet' 
  description='Shipnext uses AI to form your business Contracts and facilitate automation Client Relationship Management (CRM) processes.'
  button={<div style={{display:'flex',alignItems:'center',gap:'8px'}}>
            <RaisedButton
              style={{background:'none'}}
              onClick={props.handleOpenCreateContact}
              styles="xs"
              buttonStyle={{
                background: "#4380C7",
                color: "#fff",
                border: '1px solid #4380C7',
                width: "fit-content",
                display:'flex',
                alignItems:'center'
              }}
              primary
              label="Create contact"
            />
            <RaisedButton
              style={{background:'none'}}
              styles="xs"
              buttonStyle={{
                background: "#4380C7",
                color: "#fff",
                border: '1px solid #4380C7',
                width: "fit-content",
                display:'flex',
                alignItems:'center'
              }}
              primary
              label="CREATE COMPANY"
              onClick={props.handleOpenCreateCompany}
            />
          </div>}
  />
}

const ContactName = styled(({ contact, ...props}) => {
  return (<div {...props}>
    <Avatar contact={contact} />
    <div>
      <Text isBold >
        <div className={'flex'}>
          {contact.faved ? <span className="material-icons favorite-contact-icon">star</span> : null }
          <span className={'ellipsis'}>{`${contact.name}`}</span>
        </div>
      </Text>
      <Text emptyValue={""}>{contact.emails[0]}</Text>
    </div>
  </div>);
})`
 display: flex;
 > div:first-child {
   margin-right: 8px;
 }
  >div:nth-child(2) {
    overflow: hidden;
    >div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .favorite-contact-icon {
    font-size: 12px;
    color: var(--text-links-light);
  }
`;

export const ContactButtons = styled(({ contact, onDeleteContactClick, onAddContactClick, onMerge, ...props}) => {
  const name = `${contact.name || ''} ${contact.surname || ''}`.trim();
  let email = contact.emails[0] || '';
  if (name) {
    email = `${name} <${email}>`;
  }
  const isNew = !contact.approved;
  const hasNewSuggestions = contact.duplicateIds?.length && !isNew && onMerge;
  if (isNew) {
   return (<div onClick={muteEvent} {...props}>
    <span onClick={onAddContactClick} className="material-icons person_add_icon">person_add_alt_1</span>
     <span onClick={onDeleteContactClick} className="delete_person_icon">
        <span className="material-icons ">close</span>
     </span>
    </div>);
  }
  return (<div onClick={muteEvent} {...props}>
    { hasNewSuggestions ? <WithTooltip horizontalPosition={"left"} tip={() => <div className={cx("tooltip-default", "dark")}>{"Contact Merging"}</div> }><span className={"contact-merge-icon"}><span onClick={onMerge} className="material-icons merge-icon">merge</span></span> </WithTooltip> : null}
    { contact.emails[0] ? (<a href={`mailto:${email}`}><span className="material-icons email-icon">email</span></a>) : null }
  </div>);
})`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  padding-right: 8px;
  >span + span {
    margin-left: 8px;
  }
  .material-icons {
    font-size: 16px;
  }
  .person_add_icon {
    color: var(--bg-blue);
  }
  .email-icon{
    color: var(--text-links);
  }

  .delete_person_icon {
    background: var(--stroke-gray-2, #E6E6E6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    >span {
      font-size: 12px;
    }
  }
  .contact-merge-icon {
    background: var(--bg-blue);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 8px;
    >span {
      transform: rotate(90deg);
      color: white;
      font-size: 14px;
    }
  }
`;

function ContactRow({ row, onSelect, onDelete, onMerge, className, nextRowId, prevRowId, ...props }) {
  const tags = row.tags || [];
  return (
    <StyledRowWrapper onClick={onSelect} className={className} id={row.id} data-nextid={nextRowId} data-previd={prevRowId}>
      <Col>
        <CustomCheckbox style={{ marginTop: '-4px', marginBottom: '2px' }} checked={row.selected} onClick={ev => ev.stopPropagation()} onCheck={props.checkRow} className={s.ref_checkbox} />
      </Col>
      <Col>
        <ContactName contact={row} />
      </Col>
      <Col>
        <Text><MultiValueTd values={row.phones} renderValue={(c) => <div>{c.number}</div>} /></Text>
      </Col>
      <Col>
        <TagsView tags={tags.map(t => t.label)} showMore={2} />
      </Col>
      <Col>
        <Text><MultiValueTd values={row.companies} renderValue={(c) => <Link to={`/$1/$2/company:${c.id}/-/-/---`}>{c.name}</Link>} /></Text>
        <Text emptyValue={""}>{row.position}</Text>
      </Col>
      <Col>
        <ContactButtons contact={row} onAddContactClick={onSelect} onDeleteContactClick={onDelete} onMerge={onMerge} />
      </Col>
    </StyledRowWrapper>
      );
}

const ContactsCounters = styled(({className, counters, folder, ...props}) => {
  switch (folder) {
    case 'persons':{
      const approvedPersons = counters.totalPersons - counters.newPersons;
      return (<div className={className}>
        <div><ContactPersonIcon style={{marginTop: '-1px'}} width={36} height={36} /> {`Contacts: ${approvedPersons}`}</div>
      </div>);
    }
    case 'companies':
      return (<div className={className}>
        <div><ContactGroupIcon width={36} height={36} /> {`Groups: ${counters.totalGroups}`}</div>
        <div>{`Companies: ${counters.totalCompanies}`}</div>
      </div>);
  }
  return null;
})`
  display: flex;
  min-height: 52px;
  height: 52px;
  align-items: center;
  border-top: 1px solid var(--stroke-gray-2, #E6E6E6);
  background: var(--bg-light-gray, #F5F5F5);
  font-size: 14px;
  font-weight: 500;
  >div {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
`

class ContactsTable extends Component {
  tableHeaderProps = { background: "var(--bg-light-grey)" };
  headerProps = {};
  sortProps = { withFavorites: true };
  state = {};
  useVirtualList = true;
  static altHeaderProps = {
    hoverableProps: {
      style: {
        marginRight: 8,
        pointerEvents: 'initial',
      },
    },
    style: {
      padding: '0px 0px 0px 9px',
      minWidth: '100%',
      minHeight: '48px',
    },
  };

  async componentDidMount() {
    const { sort, filters } = this.props.contactList;
    if (this.props.lastFilters && this.props.lastFilters[`${this.props.folder}Filters`]) {
      this.props.getContactList({ page: 1, filters: { ...filters, ...this.props.lastFilters[`${this.props.folder}Filters`] }, sort: { ...sort, ...this.props.lastFilters[`${this.props.folder}Filters`].sort } });
    } else {
      this.props.getContactList({ page: 1, filters, sort });
    }
    if (this.props.getCounters) {
      this.props.getCounters();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.contactId !== nextProps.contactId ) {
      this.closeMerging();
    }
  }


  handleFilter = (filters) => {
    const { sort } = this.props.contactList;
    this.props.getContactList({ page: 1, filters, sort });
  }

  handleSort = (sort) => {
    const { filters } = this.props.contactList;
    this.props.getContactList({ page: 1, filters, sort });
  }

  handleLoadMore = async () => {
    const { sort, filters, page } = this.props.contactList;
    if (this.nextPageLoading || !this.props.contactList.next) {
      return;
    }
    this.nextPageLoading = true;
    try {
      await this.props.getContactList({ page: page + 1, filters, sort });
    } finally {
      this.nextPageLoading = false;
    }

  }

  handleOpenCreateContact = () => {
    this.closeMerging();
    history.push('/$1/persons/$3/add-person/-/---');
  }

  handleOpenCreateCompany = () => {
    this.closeMerging();
    history.push('/$1/companies/$3/add-company/-/---');
  }

  handleContactSelect = (id) => {
    const contact = this.props.contactList.data.find(c => c.id === id);
    const isNew = !contact?.approved;
    history.push(`/$1/$2/person:${id}/${(isNew && 'edit') || '-'}/-/---`);
  }

  getAltHeaderProps = (checkedCount) => {
    if (checkedCount) {
      return this.constructor.altHeaderProps;
    }
    return {};
  }

  checkAll = (checked) => {
      this.props.checkAll(checked);
  }
  checkRow = (id, e, checked) => {
      this.props.checkRow(id, checked);
  }

  handleDeleteSelectedMembers = () => {
    const toDelete = this.props.contactList.data.filter(c => c.selected).map(c => c.id);
    const contactId = this.props.contactId;
      this.setState({ confirmDeletionDialog: (<ConfirmDialog
          title=" "
          open
          handleClose={(confirm) => {
            this.setState({ confirmDeletionDialog: undefined });
            if (confirm) {
              this.props.deleteSelectedMembers(toDelete);
              if (contactId && toDelete.indexOf(contactId) !== -1) {
                history.push(`/$1/$2/-/-/-/---`);
              }
            }
          }}
        >
          <span>Are you sure you want to delete this contact(s)?</span>
        </ConfirmDialog>) });
  }

  handleDeleteNewContact = (id) => {
    return this.props.deleteSelectedMembers([id]);
  }

  handleToggleFavorites = () => {
    this.props.toggleFavorites(this.props.contactList.data.filter(ct => ct.selected));
  }

  renderTableHeader() {
    return <TableHeader rows={this.props.contactList.data} checkAll={this.checkAll} {...this.tableHeaderProps} readonly={this.props.readonly} />;
  }

  renderTableRow = (row, i, rows) => {
    const isNew = !row.approved;
    return (<ContactRow
      key={row.id}
      row={row}
      checkRow={this.checkRow.bind(this, row.id)}
      onSelect={this.handleContactSelect.bind(this, row.id)}
      className={cx(this.props.readonly && 'readonly', this.props.contactId === row.id && 'selected', isNew && 'new')}
      onDelete={this.handleDeleteNewContact.bind(this, row.id)}
      onMerge={this.startMergingSuggestion.bind(this, row.id)}
    />);
  }

  renderHeaderButtons = () => {
    return <IconButton onClick={this.handleOpenCreateContact}><PlusButtonBlue /></IconButton>;
  }

  renderHeader() {
    return <ContactsHeader
      setClearAllFilters={this.setClearAllFilters}
      user={this.props.user} {...this.props.contactList}
      folder={this.props.folder}
      handleFilter={this.handleFilter}
      buttons={this.renderHeaderButtons()}
      handleSort={this.handleSort}
      sortProps={this.sortProps}
      counters={this.props.counters}
    />
  }

  startMerging = (e, contacts) => {
    contacts = contacts || this.props.contactList.data.filter(c => c.selected);
    const key = contacts.map(c => c.id).join();
    this.setState({ mergeContactDialog: <DialogHalfScreen open key={key} >
        <MergeContactView contacts={contacts} handleClose={this.closeMerging} onMerge={this.props.mergeContacts} />
      </DialogHalfScreen> });
  }

  startMergingSuggestion = (contactId) => {
    const contact = this.props.contactList.data.find(c => c.id === contactId);
    if(!contact) {
      return;
    }
    this.startMerging(null, [contact]);
  }

  closeMerging = () => {
    if (!this.state.mergeContactDialog) {
      return;
    }
    this.setState({ mergeContactDialog: null });
  }

  setClearAllFilters=(clearFltersMethod)=>{
    this.clearAllFilters = clearFltersMethod;
  }

  handleClearFilters = async () =>{
    if(this.clearAllFilters) this.clearAllFilters()
    this.handleFilter({})
  }

  render() {
    const checkedCount = this.props.contactList.data.filter(m => m.selected).length;
    const tableHeight = this.props.counters ? 'calc(100% - 52px)' : '100%';
    return (
      <Pane
        id="mail_list_table"
        tabIndex={0}
        headerProps={{
          useBorder: true,
          children: this.renderHeader(),
          conditionalChildren: <ContactsAltHeader {...this.props.contactList} noHover folder={this.props.folder} onDelete={this.handleDeleteSelectedMembers} onToggleFavorite={this.handleToggleFavorites} onMerge={this.startMerging} />,
          condition: (checkedCount > 0),
          handleClose: (e) => this.checkAll(false),
          selectedCount: checkedCount,
          label: 'contact',
          style: { width: "auto" },
          ...this.getAltHeaderProps(checkedCount),
          ...this.headerProps,
        }}
        bodyProps={{ style: { height: '100%' } }}
        className={cx(s.table)}
        style={{ outline: "none" }}
      >
        <StyledTable height={tableHeight}>
          {this.renderTableHeader()}
          {this.props.contactList?.loading && !this.nextPageLoading ? <Loader /> : this.props.contactList.data.length ? (
            <VirtualList handleLoadMore={this.handleLoadMore} filters={this.props.contactList.filters} rows={this.props.contactList.data} useVirtual={this.useVirtualList}>
              {
                ({ rows, ...restProps }) => (
                  <div>
                    {rows.map(this.renderTableRow)}
                  </div>
                )
              }
            </VirtualList>
          ) : !this.props.contactList?.loading && <NoContacts handleOpenCreateCompany={this.handleOpenCreateCompany} handleOpenCreateContact={this.handleOpenCreateContact} handleClearFilters={this.handleClearFilters} areFiltersApplied={areFiltersApplied(this.props.contactList.filters)}  /> }
        </StyledTable>
        {this.props.counters ? (<ContactsCounters folder={this.props.folder} counters={this.props.counters} />) : null}
        {this.state.confirmDeletionDialog}
        {this.state.mergeContactDialog}
      </Pane>
    );
  }
}

export const ReduxlessContactsTable = ContactsTable;

export default connect(state => ({
  ...state.contacts,
  user: state.login.user,
  tagCategoriesByName: state.login.tagCategoriesByName,
  lastFilters: state.login.lastFilters,
}), {
  getContactList,
  getContactDetails,
  createContact,
  checkRow: checkContact,
  checkAll,
  deleteSelectedMembers: deleteContacts,
  toggleFavorites,
  getAccountFilters,
  mergeContacts,
  getCounters,
  getHeatmap,
})(withStyles(s)(ContactsTable));

const PersonGroupSwitcher = styled(({className, folder, onSwitch, ...props}) => {
    return (<div className={className}>
      <div onClick={() => history.push(`/$1/persons/$3/$4/$5/---`)} className={cx(folder === 'persons' && 'active')}>
        <ContactPersonIcon />
      </div>
      <div onClick={() => history.push(`/$1/companies/$3/$4/$5/---`)} className={cx(folder === 'companies' && 'active')} >
        <ContactGroupIcon />
      </div>
    </div>);
})`
  display: flex;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    height: 48px;
    &.active {
      border-bottom: 2px solid var(--bg-blue, #285596);
      svg {
        background: var(--bg-blue, #285596);
        border-radius: 50%;
      }
      path {
        fill: white !important;
      }
    }
  }
  >div:first-child {
    margin-right: 8px;
  }
`
