import React from 'react';
import { computeStatus } from '../shared';
import s from './../../Monitor/TableNew.scss';
import a from './../../Common/ArrowPopover.scss';
import styles from "./AllFleet.scss";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ChangesTriangle from './../../Common/ChangesTriangle';
import cx from 'classnames';
import { DisplayStatus } from './../Preview/VesselStatus';
import ConditionalBlur from './../../Common/ConditionalBlur';
import SanctionsName from '../RowBlocks/SanctionsName';
import ImoType from '../RowBlocks/ImoType';
import DwtCapacity from '../RowBlocks/DwtCapacity';
import GearRating from '../RowBlocks/GearRating';
import From from '../RowBlocks/From';
import To from '../RowBlocks/To';
import {getVesselPublicHref, isUserSubscribedToMarketplace, muteEvent} from '../../../core/utils';
import CustomCheckbox from '../../Common/CustomCheckbox';
import DisponentOwner from '../RowBlocks/DisponentOwner';
import TagsView from '../../Common/Tags/TagsView';

const labelStyle = {
  position: 'absolute',
  left: '-8px',
  top: '-5px',
  padding: '5px 0px 10px 8px',
}

function Row(props) {
  const { user, vessel, handleVesselSelected, selected, isDark = false, search, handleRowSelected, detailsBaseUrl } = props;
  let onRouteDestinationPort;
  if (vessel.onRoute && vessel.onRoute.estimateDate > new Date()) {
    onRouteDestinationPort = (vessel.onRoute.to && vessel.onRoute.to.name);
  }

  const channelStatuses = computeStatus(vessel);

  const isVesselStatusBlurred = props?.vessel?.vesselUserGroupsBlurred;
  const isBlurred = !isUserSubscribedToMarketplace(props.user)

  const selectRow = (ev, val) => {
    ev.stopPropagation();
    if (handleRowSelected) {
     handleRowSelected({
        ...vessel,
        selected: val,
      });
    }
  };

  const vesselHref = getVesselPublicHref(detailsBaseUrl, vessel);
  return (
    <a href={vesselHref} onClick={muteEvent}>
    <div
      onClick={handleVesselSelected}
      className={cx(
        s.row,
        styles.all_fleet_table_row,
        (vessel.open || onRouteDestinationPort) ? '' : s.expired,
        channelStatuses.isBidding && s.bidding,
        s[vessel.computedChannel],
        selected && s.blue_row
      )}
    >
      <ChangesTriangle vessel={vessel} />
      <div className={cx(s.table_row, styles.all_fleet_table_row)}>
        <div className={cx(s.text, s.text_ref, s.ref, s.text_refvessel)}>
          <div style={{ display: 'flex', position: 'relative', paddingLeft: user ? '25px' : null }}>
            {
              user
                ? (
                  <label style={labelStyle} onClick={ev => ev.stopPropagation()}>
                    <CustomCheckbox style={{ zIndex: 0 }} checked={!!vessel.selected} onCheck={selectRow} className={s.ref_checkbox} />
                  </label>
                )
                : null
            }
            <div style={{ overflow: "hidden" }}>
              <DisponentOwner vessel={vessel} />
              { user ?
                  <TagsView theme={{ name: (isDark && 'dark_theme') || 'white_theme' }} horizontalPosition="right" className={s.vessel_tag} tags={vessel.tags} showMore={1} />
                : null
              }
            </div>

          </div>
        </div>
        <div className={cx(s.text, s.text_vessel)}>
          <SanctionsName vessel={vessel} search={search} />
          <ImoType search={search} vessel={vessel} />
        </div>
        <DwtCapacity dwt={vessel?.deadWeight?.sDWT} capacity={vessel?.capacityV2?.grain} />
        <GearRating vessel={vessel} gear={vessel?.gearV2?.summary?.map?.(({ type, quantity, capacity }) => `${type || "---"}-${quantity || ""}-${capacity || ""}`)} isDark={isDark} />
        <From routeObj={vessel.onRoute} loadingStatus={vessel.loadingStatus} isDark={!isDark}/>
        <To routeObj={vessel.onRoute} />
        <div className={cx(s.text, s.vessel_status)}>
          {vessel?.vesselUserGroups?.groups?.length ?
            <ConditionalBlur isBlurred={isBlurred}>
              <DisplayStatus isBlurred={isVesselStatusBlurred} groups={vessel?.vesselUserGroups?.groups} />
            </ConditionalBlur>
             : '---' }

        </div>
      </div>
    </div>
    </a>
  )
}

export default withStyles(s, a)(Row)
