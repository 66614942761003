import React from "react";
import CustomTooltip from "../../Common/CustomTooltip";
import ExportButton from "./ExportButton";

/**
 * @property {Object[]} props.selectedVessels - Array of selected vessel objects.
 * @property {string} props.storageKey - A key for storing data in local storage.
 * @property {boolean} props.hideVesselOpenPositions? - Hide fields related to the open position: open position, ref, and tc rate.
 * @property {boolean} props.hideTcRate? - Hide TC Rate field if the table on the page does not have it, like in suitable fleet.
 * @property {boolean} props.isDark - For dark mode, I guess.
 */
export default function ExportButtonWithTooltip({
  selectedVessels,
  storageKey,
  hideVesselOpenPositions,
  hideTcRate,
  isDark,
}) {
  return (
    <CustomTooltip style={{ height: "auto" }} tooltip="Export to Excel" dark={isDark}>
      <ExportButton
        selectedVessels={selectedVessels}
        storageKey={storageKey}
        hideVesselOpenPositions={hideVesselOpenPositions}
        hideTcRate={hideTcRate}
      />
    </CustomTooltip>
  )
}
