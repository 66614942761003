import { fieldsMap } from "./export.constants";
import ExportVesselsApi from "../../../core/api/ExportVessels";

export async function exportVessels({ selectedVessels, orderedKeys }) {
  const [{ data: serverData }] = await Promise.all([
    loadDataForExport({ selectedVessels, orderedKeys }),
    loadXlsxJsStyle()
  ])

  const { result, headers } = processDataBeforeExport({ selectedVessels, serverData, orderedKeys })

  if (!result.length) {
    // unexpected edge case: selected vessel was suddenly deleted
    alert('No data available for export')
    return
  }

  exportXlsx({ result, headers })
}

const scriptsPromises = new Map()
const loadScript = (src) => {
  if (scriptsPromises[src]) return scriptsPromises[src]

  return scriptsPromises[src] = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = resolve;
    script.onerror = (err) => {
      delete scriptsPromises[src]
      reject(err);
    }
    document.body.appendChild(script);
  })
}

const loadXlsxJsStyle = () => loadScript('/xlsx-js-style-1.2.0.min.js')

function loadDataForExport({ selectedVessels, orderedKeys }) {
  const load = selectedVessels.map(vessel => ({
    vesselId: vessel._id,
    vesselRequestId: vessel.vesselRequestId,
    imoNumber: vessel.imoNumber,
  }))

  const select = []
  for (const key of orderedKeys) {
    const customSelect = fieldsMap[key].select
    if (customSelect) {
      select.push(...customSelect)
    } else {
      select.push(key)
    }
  }

  return ExportVesselsApi.exportVessels({ load, select })
}

function valueOrDash(val) {
  // together with QA decided that there is no sense in displaying 0
  return val === null || val === undefined || val === '' || val === 0 ? '---' : val
}

function processDataBeforeExport({ selectedVessels, serverData, orderedKeys }) {
  const result = []

  for (const selectedVessel of selectedVessels) {
    const { _id } = selectedVessel
    const row = serverData.find(x => x._id === _id)
    if (!row) continue;

    const mapped = {}
    const params = { serverVessel: row, selectedVessel }
    for (const key of orderedKeys) {
      const field = fieldsMap[key]
      if (field.values) {
        const values = field.values(params)
        for (const key in values) {
          const value = values[key]
          mapped[key] = valueOrDash(value)
        }
      } else {
        const value = field.value ? field.value(params) : row[key]
        mapped[field.exportLabel || field.label] = valueOrDash(value)
      }
    }
    result.push(mapped)
  }

  const headers = []
  for (const key of orderedKeys) {
    const field = fieldsMap[key]
    if (field.exportLabels) {
      headers.push(...field.exportLabels)
    } else {
      headers.push(field.exportLabel || field.label)
    }
  }

  return { result, headers }
}

function exportXlsx({ result, headers }) {
  const ws = XLSX.utils.json_to_sheet(result, { headers });

  const borderStyle = {
    style: 'thin',
    color: { rgb: "000000" }
  }

  const headerCellStyle = {
    fill: { fgColor: { rgb: "888888" } },
    font: { color: { rgb: "000000" } },
    alignment: { horizontal: "center" },
    border: {
      top: borderStyle,
      left: borderStyle,
      right: borderStyle,
      bottom: borderStyle,
    }
  };

  const valueCellStyle = {
    fill: { fgColor: { rgb: "DDDDDD" } },
    alignment: { horizontal: "center" },
    border: headerCellStyle.border,
  };

  const rowKeys = Object.keys(result[0]);
  const columnLengths = rowKeys.map(key => key.length);

  rowKeys.forEach((key, index) => {
    const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
    ws[cellAddress].s = headerCellStyle;
  });

  for (let r = 1; r <= result.length; r++) {
    for (let c = 0; c < rowKeys.length; c++) {
      const cellAddress = XLSX.utils.encode_cell({ c, r });
      const cell = ws[cellAddress]
      if (cell) {
        ws[cellAddress].s = valueCellStyle;

        const value = String(cell.v)
        if (value.length > columnLengths[c]) {
          columnLengths[c] = value.length
        }
      }
    }
  }

  ws['!cols'] = columnLengths.map((len) => ({ wch: Math.min(len + 6, 36) }))

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Data");
  XLSX.writeFile(wb, `Shipnext_monitor_vessels_${new Date().toISOString().split('T')[0]}.xlsx`, { cellStyles: true });
}
