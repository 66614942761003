import React from 'react'
import Tooltip from 'react-tooltip'
import s from './RominTooltip.css'
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from 'classnames';

let tooltipI = 0

const placeDirection = {
  topLeft: 'top',
  top: 'top',
  topRight: 'top',
  rightTop: 'right',
  right: 'right',
  rightBottom: 'right',
  bottomRight: 'bottom',
  bottom: 'bottom',
  bottomLeft: 'bottom',
  leftBottom: 'left',
  left: 'left',
  leftTop: 'left',
}

const placeClass = {
  topLeft: s.topLeft,
  top: s.top,
  topRight: s.topRight,
  rightTop: s.rightTop,
  right: s.right,
  rightBottom: s.rightBottom,
  bottomRight: s.bottomRight,
  bottom: s.bottom,
  bottomLeft: s.bottomLeft,
  leftBottom: s.leftBottom,
  left: s.left,
  leftTop: s.leftTop,
}

/**
 * Wrapper around react-tooltip.
 * In addition to what react-tooltip offers,
 * it supports place='topLeft|topRight|etc.' and dark mode.
 */
export const RominTooltip = withStyles(s)(({
  children,
  tip,
  place = 'top',
  dark = false,
  tooltipProps,
  ...wrapperProps
}) => {
  const id = `react-tooltip-${tooltipI++}`

  return (
    <div data-tip data-for={id} {...wrapperProps}>
      {children}
      <Tooltip
        id={id}
        effect="solid"
        place={placeDirection[place]}
        className={cx(s.tooltipWrapper, dark && s.dark)}
      >
        <div
          {...tooltipProps}
          className={cx(
            s.tooltip,
            tooltipProps?.className,
            placeClass[place],
          )}>
          {tip}
        </div>
      </Tooltip>
    </div>
  )
})
