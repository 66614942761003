import React, {PureComponent} from 'react';
import styled from "styled-components";
import type {IMessageDetails} from "../types";
import collapseCss from '../../Common/Collapse.scss';
import cx from "classnames";
import {Collapse} from "../../Common/Collapse";
import Highlight from "../../Monitor/Highlight";
import EmailActionButtons from "../EmailActionButtons";
import {EmailHeadersPopover} from "../EmailHeaders";
import {dateTime, dateTimeShiftedToLocal} from "../../../core/format";
import CustomTooltip from "../../Common/CustomTooltip";
import EmailDetailsDialog from "../EmailDetailsDialog";
import MessageContent from "../MessageContent";
import ContactDetailsPopover from "../../ContactBook/ContactDetailsPopover";
import {getAttachmentAProps} from "../../../core/utils";

type Props = {
  reply: IMessageDetails, className?: string,
  onReplyRefClick: (e: *) => void,
  refCollapse: (ref: string, el: *) => void,
  onReplyClick: (e: *) => void,
  onForwardClick: (e: *) => void,
  searchRegExp: RegExp,
  mailGatesState: *,
  isLast: boolean,
  defaultOpen: boolean,
  markAsUnread: (e: *, threadId: string) => void
}
type State = {};

const StyledWrapper = styled.div`
position: relative;
.${collapseCss.collapse_header} {
  border-radius: 8px;
  max-height: 87px !important;
  height: 87px;
  border: none !important;
  background: var(--bg-light-gray);
  margin-bottom: 0 !important;
  padding-left: 8px;
  >div:first-child{
    width: 100%;
  }
  h2, h3 {
    padding-right: 12px;
    width: calc(100% - 32px);
  }

  .original_message_head_mf {
    line-height: 24px;
    padding: 8px 0;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    text-align: left;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div:first-child {
        flex: 1;
      }

      > div:last-child {
        min-width: 125px;
        color: var(--text-medium);
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    >div:nth-child(3) {
      align-items: flex-start;
    }

    label {
      font-weight: bold;
    }
    .buttons-container {
      justify-content: flex-end;
    }
    .subject {
      overflow: hidden;
      width: 100%;
      height: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    }
  &.open {
    max-height: 1000px !important;
    height: auto;
    border-radius: 8px 8px 0 0;
    .subject {
      height: auto;
      white-space: normal;
    }
  }
  }
}
  .${collapseCss.collapse_wrapper} {
    margin-bottom: 0!important;
    border-radius: 8px;
  }
  .${collapseCss.collapse_body} {
    background: var(--bg-light-gray);
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }
  div.original_message_text {
    padding: 12px 0px 12px 0px;
    background: var(--bg-light-gray);
    border-top: 1px solid var(--stroke-light-gray1);
    margin-top: 1px ;
    border-radius: 0 0 8px 8px;
    > * {
      padding-left: 39px;
      padding-right: 12px;
    }
    >footer {
      padding-left: 0;
      padding-right: 0;
     > * {
       padding-left: 39px;
       padding-right: 12px;
     }
    }
    .attachments {
      margin-top: 8px;
      border-top: 1px dashed var(--stroke-light-gray1);
      padding-top: 8px;

      > div {
        display: flex;

        > span:first-child {
          font-size: 20px;
          color: var(--text-medium);
          transform: rotate(45deg);
        }

        a {
          margin-left: 4px;
          word-break: break-all;
        }
      }

      > div + div {
        margin-top: 8px;
      }
    }

  }
  .scroll_anchor {
    position: absolute; top: -50px; left: 0;
  }
  &.active {
    .${collapseCss.collapse_header}::after {
      content: "";
      position: absolute;
      height: 88px;
      left: 0px;
      width: 0;
      border-left: 2px solid var(--text-links);
    }
  }
  footer {
    padding-top: 6px;
    >div:first-child {
      display: flex;
      justify-content: space-between;
      color: var(--text-medium);
    }
    .ref{
      display: flex;
      align-items: center;
      >span:first-child{
        transform: rotate(90deg);
        font-size: 16px;
        color: var(--text-links);
      }
      >small {
      line-height:11px;
      }
    }
  }
    &.sent {
      .${collapseCss.collapse_header}, .${collapseCss.collapse_body}, .original_message_text {
        background: #f6f9fc;

        .ref {
          > span:first-child {
            transform: rotate(-90deg);
            color: var(--icon-default);;
          }
        }

      }
    }
`


class MailFlowItem extends PureComponent<Props, State> {

  componentDidMount() {

  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.searchRegExp !== this.props.searchRegExp && nextProps.searchRegExp && !this.collapse.state.open) {
      if (this.props.reply.text.search(nextProps.searchRegExp) !== -1) {
        this.collapse.show();
      }
    }
  }

  refCollapse = (collapse) => {
    this.collapse = collapse;
    if (this.props.refCollapse) {
      this.props.refCollapse(collapse);
    }
  }


  render() {
    const props = this.props;
    const { reply } = props;
    const attachments = reply.attachments;
    return (
      <StyledWrapper id={`mail-reply-${reply.refNo}`} className={cx(props.className, reply.sentByClient && 'sent')} data-email-ref={reply.refNo} >
          <div className="scroll_anchor" />
          <Collapse
            defaultOpen={props.defaultOpen}
            noBorder={true}
            innerRef={this.refCollapse}
            renderClosed={false}
            title={<div className="original_message_head_mf">
              <div>
                <div><label>From: </label>
                  <ContactDetailsPopover email={reply.from[0]}>
                    <a href={`mailto:${reply.from[0]}`}>
                      <Highlight refNo={reply.refNo} search={props.searchRegExp}>{reply.from[0]}</Highlight>
                    </a>
                  </ContactDetailsPopover>
                </div>
                <div className={'buttons-container'} >
                  <EmailActionButtons
                    onReplyClick={props.onReplyClick}
                    onForwardClick={props.onForwardClick}
                    canReply={reply.replyTemplate?.replyGates?.length}
                    sentByClient={reply.sentByClient}
                    onUnreadClick={(e) => props.markAsUnread(e, reply.id)}
                    readBy={reply.readBy}
                  />

                </div>
              </div>
              <div>
                <div><label>To: </label>
                  <ContactDetailsPopover email={reply.to[0]}>
                    <a href={`mailto:${reply.to[0]}`}>
                      <Highlight refNo={reply.refNo} search={props.searchRegExp}>{reply.to[0]}</Highlight>
                    </a>
                  </ContactDetailsPopover>
                  <EmailHeadersPopover message={reply} />
                </div>
                <div>
                  {reply.sentByClient ? dateTimeShiftedToLocal(reply.receivedAt) :(
                    <CustomTooltip horizontalAlign={'left'} tooltip={<div>
                      <div>Shipnext Received: {dateTimeShiftedToLocal(reply.createdAt)}</div>
                      <div>Received: {dateTimeShiftedToLocal(reply.receivedAt)}</div>
                    </div>}><div>{dateTimeShiftedToLocal(reply.receivedAt)}</div></CustomTooltip>
                  )}
                </div>
              </div>
              <div>
                <div className="subject" ><label>Subject: </label><strong><Highlight refNo={reply.subject} search={props.searchRegExp}>{reply.subject}</Highlight></strong></div>
                <div><EmailDetailsDialog message={reply}/> <small>IN REPLY: <a onClick={props.onReplyRefClick}>{reply.replyToRef}</a></small></div>
              </div>
            </div>}
          >
            <div className="original_message_text">
              <div><pre>
          <MessageContent refNo={reply.refNo} search={props.search}>{reply.html}</MessageContent>
        </pre></div>
              <footer>
                <div>
                  <div></div>
                  <div className={"ref"}><span className="material-icons">forward</span><small>REF: {reply.refNo}</small></div>
                </div>
                {attachments?.length ? <div className={"attachments"}>
                  {attachments.map(file => {
                    return (
                    <div>
                      <span className="material-icons-outlined">
                        attach_file
                      </span>
                      <a {...getAttachmentAProps(file)}>{file.name}</a>
                    </div>);
                  })}
                </div> : null}
              </footer>
            </div>
          </Collapse>
      </StyledWrapper>
    );
  }
}

export default MailFlowItem;
