import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import s from '../../Documents/Cargo.scss';
import { sendRequest } from '../../../actions/cargo';
import { staticPopup } from '../../../actions/runtime';
import mergeTerms from '../../Documents/mergeTerms';
import Request from './Request';
import { grabErrorMessage } from '../../../core/utils';
import Contract from './../../../core/api/Contract';
import { createPreviewAdapter, createServerAdapter } from '../../Documents/adapters';
import { withConfirmationDialog } from '../../../core/HOC';
import history from '../../../core/history';
import TcOfflinePreview from './TcOfflinePreview';
import TcHeader from './TcHeader';
import PropTypes from 'prop-types';
import PubSub from "pubsub-js";

class AddFormTC extends Component {
  static propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    sendRequest: PropTypes.func,
  };

  static contextTypes = {
    cargoTypes: PropTypes.array,
    voyageTerms: PropTypes.array,
    showMessage: PropTypes.func,
    blockTransition: PropTypes.func,
  };
  // type determines routes transition (e.g. to edit, back, etc...)
  // can be "voyage" or "bookingnote" for now
  static defaultProps = {
    previewAdapter: createPreviewAdapter(),
    serverAdapter: createServerAdapter(),
    type: 'tc',
  }

  state = {
    termsCounter: 0,
    previewErrors: null,
    request: {
      contract: this.props.defaultContractPDF,
    },
  };
  fields = {};
  // Request component ref to manipulate form steps
  _requestElement = null;

  constructor(props, context) {
    super(props);
    this._form = props.cargo;

    if (this._form) {
      this._form.contract = this._form.contract._id;
      this._form.terms = mergeTerms(this._form.terms, this._form.terms);
    }
    this.props.refEl(this);
  }


  handlePreview = (request) => {
    const defaultAccount = this.props.user.company.accounts?.find(a => a.default);
    request.bankingDetails = defaultAccount ? {
      ...defaultAccount,
      ibanNo: defaultAccount.ibanNumber,
      bank: defaultAccount.bankName,
    } : undefined;
    this.setState({
      preview: request,
    });
  };
  handleSend = () => {
    this.fields.requestType.setValue('request');
  };

  handleSubmit = (e, request) => {
    this.props.unblock && this.props.unblock();
    if (request) {
      const defaultAccount = this.props.user.company.accounts?.find(a => a.default);
      request.bankingDetails = defaultAccount ? {
        ...defaultAccount,
        ibanNo: defaultAccount.ibanNumber,
        bank: defaultAccount.bankName,
      } : undefined;
    }
    return this.createNewOfflineContract(request || this.state.preview);
  };

  createNewOfflineContract = async adaptedRequest => {
    try {
      const res = await Contract.createOfflineTimeCharterContract(adaptedRequest);

      this.context.showMessage({
        message: `Offline contract has been created.`,
      });
      if (this.props.isFillingSystem) {
        history.push('#');
        this.setState({
          preview: undefined,
        });
        this.props?.handleSuccess?.(adaptedRequest);
        return;
      }
      history.push(`/contracts/${this.props.type}`);
      PubSub.publish("documents::contractCreated");
      this.props?.handleSuccess?.(adaptedRequest);
    } catch (error) {
      console.error(error);
      this.context.showMessage({
        level: 'error',
        message: `Error creating offline contract: ${grabErrorMessage(error)}`,
      });
    }
  }
  createPreviewPdf = async request => {
    try {
      const res = await Contract.createOfflineTimeCharterContractPreview(request);
      return res;
    } catch (error) {
      console.error(error);
      this.context.showMessage({
        level: 'error',
        message: `Error creating preview contract: ${grabErrorMessage(error)}`,
      });
    }
  }

  closePreview = () => {
    this.props.unblockAndBlockTransition?.();
    history.push('#');

    this.setState({
      preview: undefined,
    });
  }

  handleClosePreview = () => {
   this.closePreview();

    if (this._requestElement) {
      this._requestElement.openStepWithInvalidInput();
    }
  };

  handleBackToEdit = () => {
    this.closePreview();

    if (this._requestElement) {
      this._requestElement.openFirstStep();
    }
  }

  handleClose = () => {
    if (this.props.user) {
      history.push('/my/cargo');
    } else {
      history.push('/');
    }
  };

  saveRequestRef = el => this._requestElement = el;

  onChange = request => {
    this.setState({
      request,
    });
  }

  render() {
      const { errors, loading, vessel } = this.props;

      const title = this.props.title ? this.props.title(this.state.preview) : "ADD NEW TIME CHARTER";

    const headerProps = {
      title,
      handleClose: this.state.preview ? this.handleBackToEdit : this.props.handleClose,
      withButtons: this.state.preview,
      handleSubmit: this.state.preview ? this.handleSubmit : null,
    };
    return (
      <div className={s.root} style={{ display: "flex", flexDirection: "column" }}>
        <TcHeader {...headerProps} />
        <div className={s.container} style={{ paddingRight: "22px" }}>
          <div>
            <Request
              vessel={vessel}
              innerRef={this.saveRequestRef}
              styles={s}
              errors={errors}
              tc={{}}
              request={this.state.request}
              onChange={this.onChange}
              loading={loading}
              handlePreview={this.handlePreview}
              handleSubmit={this.handleSubmit}
            />
          </div>

        </div>
        {this.state.preview
          ?
            <TcOfflinePreview createPdf={this.createPreviewPdf} preview={this.state.preview} />
          : null}

      </div>
    );
  }
}

export default connect(state => ({ ...state.cargo, user: state.login.user }), {
  sendRequest,
  staticPopup,
})(withStyles(s)(withConfirmationDialog({ style: { height: '100%' } })(AddFormTC)));
