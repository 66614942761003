/*
 * @Author: Sergiy Samborskiy
 * @Date: 2017-10-19 19:17:22
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-11-29 17:44:53
 */

import React from "react";

import s from "../Chat.scss";
import cx from "classnames";
import { connect } from "react-redux";

import { debounce } from "formsy-material-ui/lib/utils";
import { convertDatesFromUTC } from "../../../core/api/api";
import Link from "../../Link/Link";

import { sendMessage, setReadTimestamp, setTargetRoom, messagesReceived, fetchContactHistory } from "../actions";
import { selectAllContactMessages, selectContactRooms, getMe } from "../selectors";
import { genericMessage } from "../Messages";
import { ChatRoomHeader } from "./ChatRoomHeader";
import { SendMessageArea } from "./SendMessageArea";

class FoundResults {
    _set = new Map();
    _items = [];
    _index = -1;

    add(id) {
        this._items.push(id);
        this._set.set(id, this._items.length - 1);
    }

    has(id) {
        return this._set.has(id);
    }

    get value() {
        return this._items[this._index];
    }

    get index() {
        return this._index;
    }

    nextIndex() {
        this._index++;
        if (this._index >= this._items.length) {
            this._index = 0;
        }
        return this._index;
    }

    static Empty = new FoundResults();
}

class ChatRoom extends React.Component {

    constructor(...args) {
        super(...args);

        this.handleSendMessage = this.handleSendMessage.bind(this);
        this.renderMessages = this.renderMessages.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
        this.handleMoveNextResult = this.handleMoveNextResult.bind(this);

        this.messagesContainerRef = undefined;

        /**
         * @type {Map<string, HTMLElement>}
         */
        this.messageRefs = new Map();
        this.nextUnreadMessage = undefined;

        this.setReadTimestamp = debounce(setReadTimestamp, 2000);

        this.initiallyScrolled = false;

        // TODO: fetching status should be stored in redux state
        this.fetching = false;
        this.allFetched = false;
        this.scrollPosition = 0;
        this.justFetched = false;

        this.selfFiredDummyScrollAction = 0;

        this.autoScrollThreshold = 50;
        this.fetchHistoryThreshold = 100;

        this.state = {
            searchText: "",
            foundResults: FoundResults.Empty,
        };
    }

    componentDidMount() {
        this.messagesContainerRef.addEventListener("scroll", this.onScroll);

        this.scrollIfNeeded();

        this.prevScrollHeight = this.messagesContainerRef.scrollHeight;
    }

    componentWillUnmount() {
        this.messagesContainerRef.removeEventListener("scroll", this.onScroll);
    }

    componentDidUpdate() {
        const { rooms } = this.props;

        this.scrollIfNeeded();

        if (this.justFetched) {
            this.justFetched = false;
            this.messagesContainerRef.scrollTop = this.scrollPosition = this.scrollPosition + (this.messagesContainerRef.scrollHeight - this.prevScrollHeight);
        }

        this.prevScrollHeight = this.messagesContainerRef.scrollHeight;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.userId !== nextProps.userId) {
            this.initiallyScrolled = false;
            this.allFetched = false;
            this.fetching = false;
            this.lastFetchDate = undefined;
            this.nextUnreadMessage = undefined;
            this.setState({
                searchText: "",
                foundResults: FoundResults.Empty
            });
            return;
        }


        const foundResults = this.searchForMatchingMessages(nextProps.messages, this.state.searchText);
        this.setState({
            foundResults
        });
    }

    scrollIfNeeded() {
        if (!this.selfFiredDummyScrollAction) {
            clearImmediate(this.selfFiredDummyScrollAction);
            this.selfFiredDummyScrollAction = 0;
        }
        if (this.initiallyScrolled) {
            // after initial scroll we auto scroll room only if new message added
            // and user don't scroll-out to far from bottom

            const { clientHeight, scrollTop } = this.messagesContainerRef;

            if (this.prevScrollHeight - scrollTop - clientHeight < this.autoScrollThreshold) {
                this.scrollToBottom();
            }
        }
        else {
            // for first time we scroll chat toward unread messages if exist
            this.initiallyScrolled = true;
            if (this.nextUnreadMessage === null) {
                this.scrollToBottom();
            }
            else {
                this.scrollToMessage(this.nextUnreadMessage);
            }

            this.fetchContactMessages(this.props.rooms, this.props.messages);
            this.onScroll(true);
        }
    }

    fetchContactMessages(rooms, messages) {
        let dates = [];
        const toFind = rooms.map(room => room._id);

        for (const message of messages) {
            const index = toFind.findIndex(roomId => roomId === message.roomId);
            if (index !== -1) {
                toFind.splice(index, 1);
                dates.push(message.sentAt);
                if (toFind.length === 0) {
                    break;
                }
            }
        }

        dates = dates.sort((a, b) => a.getTime() - b.getTime());

        if (this.lastFetchDate === dates[dates.length - 1]) {
            dates.splice(dates.length - 1, 1);
        }
        this.lastFetchDate = dates[dates.length - 1];

        const timestamp = this.lastFetchDate;
        this.fetchMessages(timestamp).catch(err => {
            console.error("Error while fetching contact history", err);
        })
    }

    onScroll(isFirstDummyScroll = false) {
        const { messages, rooms, userId } = this.props;

        if (!this.justFetched) {
            this.scrollPosition = this.messagesContainerRef.scrollTop;
        }

        if (messages.length === 0) {
            return;
        }

        if (this.messagesContainerRef.scrollTop < this.fetchHistoryThreshold) {
            // in fact we can check if message count is less than 50 (InitialMessageCount) no more messages exists
            // 'isFirstDummyScroll === true' is intended do not simplify to just 'isFirstDummyScroll'


            this.fetchContactMessages(rooms, messages);
        }

        if (this.nextUnreadMessage === null) {
            return;
        }

        this.computeReadTimestamp();
    }

    async fetchMessages(beforeTime) {
        const { userId } = this.props;
        if (this.fetching || this.allFetched) {
            return;
        }
        this.fetching = true;
        const newMessages = convertDatesFromUTC(await fetchContactHistory(userId, beforeTime));

        // if another contact selected while fetching history
        if (userId === this.props.userId) {
            this.fetching = false;
            if (newMessages.length === 0) {
                this.allFetched = true;
            }
        }

        this.justFetched = true;
        this.props.messagesReceived(newMessages);
    }

    computeReadTimestamp() {
        const { messages, rooms } = this.props;

        if (messages.length === 0) {
            return;
        }
        const lastIndex = messages.length - 1;
        let i = lastIndex;
        do {
            const mEl = this.messageRefs.get(messages[i]._id);
            if (!mEl) {
                throw new Error("Visibility check error");
            }

            if (this.isVisible(mEl)) {
                this.nextUnreadMessage = i < lastIndex ? messages[i + 1]._id : null;

                // we can set last read timestamp same to all rooms in this discussion
                const timestamps = rooms.reduce((acc, val) => (acc[val._id] = messages[i].sentAt.valueOf(), acc), {});

                this.setReadTimestamp(timestamps);

                break;
            }

        }
        while(messages[i]._id !== this.nextUnreadMessage && i-- >= 0);
    }

    /**
     * Check if target element is visible in viewport
     * @param {HTMLElement} el
     * @return {boolean}
     */
    isVisible(el) {
        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    handleSendMessage(text) {
        const { userId, messages, rooms, targetRoom, setTargetRoom } = this.props;


        const lastMessage = messages[messages.length - 1];
        const roomId = targetRoom || (lastMessage && lastMessage.roomId) || rooms.find(r => r)._id;
        if (!roomId) {
            return console.error("Can't send message to unknown room");
        }

        if (!text || !(text = text.trim())) {
            return console.error("Can't send empty message");
        }

        if (targetRoom) {
            setTargetRoom(userId, undefined);
        }

        const wait = sendMessage(roomId, { content: text });
        wait.then((res) => {
            setTimeout(()=> {
              this.scrollToMessage(res._id);
            });
        })
    }

    handleChangeSearchText(text) {
        const foundResults = this.searchForMatchingMessages(this.props.messages, text);

        this.setState({
            searchText: text,
            foundResults
        });
    }

    handleMoveNextResult() {
        const { foundResults } = this.state;

        foundResults.nextIndex();

        this.scrollToMessage(foundResults.value);
    }

    scrollToMessage(messageId) {
        const el = this.messageRefs.get(messageId);
        if (el) {
            this.scrollIntoView(el);
        }
    }

    /**
     *
     * @param {HTMLElement} el
     */
    scrollIntoView(el) {
        const parent = el.parentNode;
        parent.scrollTop = el.offsetTop - parent.offsetTop;
    }

    scrollToBottom() {
        this.messagesContainerRef.scrollTop = this.messagesContainerRef.scrollHeight;
    }

    *renderMessages(messages, foundMessages) {
        const { me, rooms } = this.props;
        let lastDay = undefined;
        let prevRefNo = undefined;

        const makeMessageRef = (messageId) => {
            return (el) => this.messageRefs.set(messageId, el);
        };

        this.nextUnreadMessage = null;

        for (const message of messages) {
            const nowDate = message.sentAt.toLocaleDateString();
            const room = rooms.find(room => room._id === message.roomId);
            const lastRead = room.readState[me] || new Date(0);

            const read = message.sentAt <= lastRead;

            if (!this.nextUnreadMessage && lastRead < message.sentAt) {
                this.nextUnreadMessage = message._id;
            }

            const messageRef = makeMessageRef(message._id);

            if (lastDay !== nowDate) {
                lastDay = nowDate;
                yield renderDateDivider(nowDate, message._id);
            }
            if (prevRefNo !== room.refNo) {
                prevRefNo = room.refNo;
                yield renderRefNoDivider(room.refNo, message._id, room.referenceInfo);
            }

            yield genericMessage({ message, me, messageRef, read, found: foundMessages.has(message._id) ? this.state.searchText : undefined });
        }
    }

    searchForMatchingMessages(messages, text) {
        if (text.length < 3) {
            return FoundResults.Empty;
        }
        const map = new FoundResults();
        for (const message of messages) {
            if (message.content.indexOf(text) !== -1) {
                map.add(message._id);
            }
        }
        return map;
    }

    render() {
        const { searchText, foundResults } = this.state;
        const { userId, user, messages, closeRoom, onlineUserIds } = this.props;

        return (
            <div className={cx(s.contact, userId ? s.contact_big : null)}>
                <ChatRoomHeader
                  user={user}
                  searchText={searchText}
                  onChangeSearchText={this.handleChangeSearchText}
                  onMoveNext={this.handleMoveNextResult}
                  closeRoom={closeRoom}
                  isOnline={user && onlineUserIds.has(user._id)}
                />
                <hr className={s.line} />
                <div ref={el => this.messagesContainerRef = el} id="body_message" className={s.body_message}>
                    {
                        Array.from(this.renderMessages(messages, foundResults))
                    }
                </div>
                <SendMessageArea sendMessage={this.handleSendMessage} />
            </div>
        );
    }
}


function renderDateDivider(date, msgId) {
    return (
        <div key={date + msgId} className={cx(s.divider, s.divider_date)}>
            <p>{date}</p>
        </div>
    );
}

function renderRefNoDivider(refNo, msgId, refData) {
  if (!refNo) return null

  const link = generateMonitorLink(refData);
  return (
    <div key={refNo + msgId} className={cx(s.divider, s.divider_refno)}>
      <p>
        REF:
        {link ?
          <Link to={link}>{refNo}</Link> :
          refNo
        }
      </p>
    </div>
  );
}

function generateMonitorLink({ type, id } = {}) {
  if (!type || !id) {
    return undefined;
  }
  switch (type) {
    case 'cargoRequest':
      return `/main-deck/general/${id}`;
    case 'vesselRequest':
      return `/main-deck/general/-/${id}`;
    case 'billOfLading':
      return `/contracts/bill-of-lading/${id}`;
  }
}

/**
 * @param {{ room: { _id: string } }} props
 */
const BoundChatRoom = connect((state, props) => {
    const { userId } = props;
    let messages = [];
    let rooms = [];
    let targetRoom = undefined;

    if (userId) {
        rooms = selectContactRooms(state, userId).map(r => state.chat.rooms[r]);
        messages = selectAllContactMessages(state, userId);
        targetRoom = state.chat.targetRoom[userId];
    }

    return {
        me: getMe(state),
        user: state.chat.users[userId],
        targetRoom,
        rooms,
        messages
    };
}, {
    setTargetRoom,
    messagesReceived
})(ChatRoom);

export { ChatRoom, BoundChatRoom };
