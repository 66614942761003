import Api from './api';

export default class ExportVesselsApi extends Api {
  static exportVessels(params) {
    return ExportVesselsApi.fetch(
      `/export-vessels`,
      {
        method: 'POST',
        body: JSON.stringify(params)
      },
      '/api/v2',
    )
  }
}
