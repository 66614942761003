/*
 * @Author: Sergiy Samborskiy
 * @Date: 2017-10-19 19:29:14
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-10-25 14:23:02
 */

import React from "react";

import s from "../Chat.scss";
import cx from "classnames";

import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import IconNavigation from "material-ui/svg-icons/navigation/more-vert";
import IconClose from "material-ui/svg-icons/navigation/close";
import IconSearch from "material-ui/svg-icons/action/search";

import { getContactNamesPair } from "../selectors";
import { BlurEmailIfNoSubscription } from '../../Signup/BlurIfNoSubscription';


export class ChatRoomHeader extends React.Component {

    constructor(...args) {
        super(...args);

        this.setSearchOpenState = this.setSearchOpenState.bind(this);
    }

    state = {
        searchPanelExpanded: false,
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.user && nextProps.user && this.props.user._id !== nextProps.user._id) {
            this.setState({
                searchPanelExpanded: false
            });
        }
    }

    setSearchOpenState(isOpen) {
        this.setState({
            searchPanelExpanded: isOpen
        });
    }

    render() {
        const { searchPanelExpanded } = this.state;
        const { user, searchText, onChangeSearchText, onMoveNext, closeRoom, isOnline } = this.props;

        const [userName, companyName] = getContactNamesPair(user);

        return (
            <div className={s.contact_header}>
              <div
                className={s.part_left}
                style={{
                  width: searchPanelExpanded ? '30%' : 'calc(100% - 32px - 10px)'
                }}
              >
                <IconClose
                  color={"#CBCBCB"}
                  onClick={closeRoom}
                  style={{
                    cursor: "pointer"
                  }}
                />

                <div className={s.wrapper_name}>
                  <div>
                    <BlurEmailIfNoSubscription>
                      <span
                        title={userName}
                        className={s.contact_name}
                      >
                        <span style={{ position: 'relative', display: 'inline' }}>
                          {userName}
                          {isOnline && <div className={s.online_circle}/>}
                        </span>
                      </span>
                    </BlurEmailIfNoSubscription>
                  </div>
                  <BlurEmailIfNoSubscription>
                    <span
                      title={companyName}
                      className={s.company_name}
                    >
                      {companyName}
                    </span>
                  </BlurEmailIfNoSubscription>
                </div>
              </div>
              <div className={s.part_right}>
              <RenderSearch opened={searchPanelExpanded} text={searchText} setText={onChangeSearchText} moveNext={onMoveNext} open={this.setSearchOpenState} />
                    { renderMenu() }
                </div>
            </div>
        );

    }
}

function RenderSearch(props = {}) {
    const { opened, text, open, setText, moveNext } = props;
    let input;

    return (
        <div
            className={s.search}
            style={{
                marginRight: "4px",
                backgroundColor: opened ? "#F5F5F5" : "transparent"
            }} >
            <IconSearch
                style={{
                    cursor: "pointer"
                }}
                color={"#CBCBCB"}
                onClick={() => { open(true); input.focus(); }}
            />
            <input
                ref={i => input = i}
                style={{
                    width: opened ? "88%" : "0"
                }}
                type="text"
                value={text}
                onChange={e => setText(e.target.value)}
                onKeyPress={e => e.key === "Enter" ? moveNext() : void 0 }
                onBlur={e => open(false)}
            />
        </div>
    );
}

function renderMenu(props) {
    return null;
    return (
        <IconMenu style={{ display: "none" }}
            iconButtonElement={
                <IconButton>
                    <IconNavigation
                        style={{
                            width: "45px",
                            height: "45px"
                        }}
                    />
                </IconButton>
            }
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            targetOrigin={{ horizontal: "right", vertical: "top" }}
        >
            <MenuItem
                style={{
                    minHeight: "40px",
                    lineHeight: "40px",
                    fontSize: "14px"
                }}
                primaryText="View profile"
            />
            <MenuItem
                style={{
                    minHeight: "40px",
                    lineHeight: "40px",
                    fontSize: "14px"
                }}
                primaryText="Settings"
            />
            <MenuItem
                style={{
                    minHeight: "40px",
                    lineHeight: "40px",
                    fontSize: "14px"
                }}
                primaryText="Sign out"
            />
        </IconMenu>
    );
}
