exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1xaav {\n  border: none;\n  background: none;\n  width: 134px;\n  height: 30px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  -ms-flex-align: center;\n      align-items: center;\n  cursor: pointer;\n  -webkit-transition: -webkit-transform 0.5s;\n  transition: -webkit-transform 0.5s;\n  transition: transform 0.5s;\n  transition: transform 0.5s, -webkit-transform 0.5s;\n  padding: 2px 0; }\n\n._3N_Qd {\n  border-radius: 20px;\n  overflow: hidden;\n  width: 100%;\n  height: 100%; }\n\n._3N_Qd > div {\n  width: 115px;\n  height: 30px;\n  position: relative;\n  border-radius: 20px;\n  overflow: hidden; }\n\n._1xaav:active {\n  -webkit-transform: scale(0.95);\n          transform: scale(0.95); }\n\n._2VsAB {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  width: 100%;\n  height: 100%; }\n\n.LHi-h {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  width: 60px;\n  height: 100%;\n  background-color: rgba(77, 158, 14, 0.1);\n  color: #2E6206;\n  text-align: center;\n  font-family: Roboto;\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 12px !important;\n  border-radius: 20px 0 0 20px;\n  overflow: hidden;\n  padding-bottom: 2px; }\n\n._2VsAB ._1lZsC {\n  background-color: rgba(153, 153, 153, 0.1);\n  color: #fff; }\n\n._2llfk {\n  width: 74px;\n  height: 100%;\n  background-color: #75c422;\n  color: #fff;\n  font-family: \"Roboto Condensed\";\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 12px !important;\n  text-transform: uppercase;\n  border-radius: 0 20px 20px 0;\n  overflow: hidden;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center; }\n", ""]);

// Exports
exports.locals = {
	"button_wrapper": "_1xaav",
	"loader_wrapper": "_3N_Qd",
	"content_wrapper": "_2VsAB",
	"currency_wrapper": "LHi-h",
	"dark": "_1lZsC",
	"book": "_2llfk"
};