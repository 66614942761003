import React from 'react'
import SecondText from '../../Monitor/RowBlocks/SecondText';
import cx from 'classnames';
import s from './../../Monitor/TableNew.scss';
import styles from './../AllFleet/AllFleet.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { ProgressLine } from '../../Common/ProgressVesselPosition';
import CustomTooltip from '../../Common/CustomTooltip';

function From({ routeObj, loadingStatus, isDark }) {

  const comingFrom = routeObj?.route?.from?.name || "---";

  const speed = routeObj?.lastPos?.speed ? routeObj.lastPos.speed + " kn" : "---";
  return (
    <div className={cx(s.text, styles.with_tooltip)}>
      <span title={comingFrom ? comingFrom : undefined} className={cx(s.bold_text, s.uppercased, s.departure_port)}>
        {
          comingFrom
        }
      </span>
      <span className={cx(s.progress_wrapper, styles.with_tooltip)}>
        <CustomTooltip
          className={styles.loading_tooltip_wrapper}
          dark={isDark}
          verticalAlign="top"
          tooltip={
            <div className={styles.loading_tooltip}>
              <div className={styles.loading_tooltip_item}>
                <span className={styles.tooltip_arrow}><ProgressLine showPercent={false} onRoute={{ route: { progress: loadingStatus ? 1 : 0 } }} loadingStatus={loadingStatus} /></span>
                <span className={styles.tooltip_text}>{loadingStatus || "Unknown"}</span>
              </div>
            </div>}
        >
          <ProgressLine showPercent={false} onRoute={routeObj} loadingStatus={loadingStatus} />
        </CustomTooltip>
      </span>
      <span className={s.row_block}>
        <SecondText>{speed}</SecondText>
      </span>
    </div>

  )
}

export default withStyles(s)(From);
