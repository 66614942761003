import { MenuItem } from 'material-ui';
import React from 'react';
import Link from '../Link/Link';

export const links = [
  {
    name: "CARGO CONTRACTS",
    id: "voyage",
    url: '/contracts/voyage/',
  },
  {
    name: "FLEET CONTRACTS",
    id: "tc",
    url: '/contracts/tc/',
  },
  {
    name: "BILLS OF LADING",
    id: "bl",
    url: '/contracts/bill-of-lading',
  },
  {
    name: 'INVOICES',
    id: 'invoice',
    url: '/contracts/invoice'
  },
];
export const renderMenu = list => list.map(item => (
  <Link to={item.url}>
    <MenuItem primaryText={item.name} value={item.id} key={item.url} />
  </Link>
));

export const headerStyle = { fontWeight: 700, fontSize: '14px', marginTop: "0px", marginBottom: "0px", whiteSpace: "nowrap", color: 'var(--text-dark)' };
